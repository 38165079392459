import React, { Component, Fragment } from "react";
import {
  GridRow,
  GridColumn,
  Grid,
  Input,
  Icon,
  Popup,
  Image,
  Header,
  Form,
  Button,
  Checkbox,
  Container,
  Dropdown,
  Message,
  Segment
} from "semantic-ui-react";
import { connect } from "react-redux";
import { UpdateUser } from "../Actions/UsersActions";
import { SignUp, SignIn } from "../Util/Auth";
import { withRouter, Link } from "react-router-dom";

const options = [
  { key: 1, text: "Petrochemical/Refining", value: "Petrochemical/Refining" },
  { key: 2, text: "Chemical Processing", value: "Chemical Processing" },
  {
    key: 3,
    text: "Storage/Pipeline/Transportation",
    value: "Storage/Pipeline/Transportation"
  },
  {
    key: 4,
    text: "Oil & Gas Exploration/Production",
    value: "Oil & Gas Exploration/Production"
  },
  {
    key: 5,
    text: "Power Generation/Utilities",
    value: "Power Generation/Utilities"
  },
  { key: 6, text: "Discreet Manufacturing", value: "Discreet Manufacturing" },
  { key: 7, text: "Food/Pharmaceuticaln", value: "Food/Pharmaceuticaln" },
  {
    key: 8,
    text: "Industrial Services/Products",
    value: "Industrial Services/Products"
  },
  {
    key: 9,
    text: "Other (Specify industry in the field below)",
    value: "Other (Specify industry in the field below)"
  }
];

class Registration extends Component {
  routeTo = route => {
    this.props.history.push(`/${route}`);
  };

  state = {
    loading: false,
    firstName: "",
    lastName: "",
    jobTitle: "",
    departmentName: "",
    companyName: "",
    location: "",
    industryCategory: "",
    otherIndustryCategory: "",
    workemail: "",
    password: "",
    nexstep: false,
    agree: false
  };

  clearState = () => {
    this.setState({
      firstName: "",
      lastName: "",
      jobTitle: "",
      departmentName: "",
      companyName: "",
      location: "",
      industryCategory: "",
      otherIndustryCategory: "",
      workemail: "",
      password: "",
      nexstep: false
    });
  };

  handleOnChange = (e, { name, value }) =>
    this.setState({ [name]: value, errorMessage: null });
  handleChange = (e, { value }) => this.setState({ industryCategory: value });

  cognitoRegisterUser = () => {
    let { workemail, password } = this.state;
    SignUp(
      {
        username: workemail,
        password: password
      },
      (error, success) => {
        if (error) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ loading: true }, () => {
            SignIn(
              {
                username: workemail,
                password: password
              },
              (error, success) => {
                if (error) {
                  this.setState({ errorMessage: error.message });
                } else {
                  this.setState({
                    password: "",
                    confirmpassword: "",
                    loading: false,
                    nexstep: true
                  });
                }
              }
            );
          });
        }
      }
    );
  };

  updateTheUser = cb => {
    let {
      firstName,
      lastName,
      departmentName,
      companyName,
      location,
      industryCategory,
      otherIndustryCategory,
      jobTitle
    } = this.state;
    this.props.updateUser(
      {
        firstName: firstName,
        lastName: lastName,
        departmentName: departmentName,
        companyName: companyName,
        location: location,
        industryCategory:
          otherIndustryCategory !== ""
            ? otherIndustryCategory
            : industryCategory,
        jobTitle: jobTitle
      },
      cb
    );
  };

  toggle = () => this.setState(prevState => ({ agree: !prevState.agree }));

  registrationForm = () => {
    let {
      workemail,
      nexstep,
      industryCategory,
      otherIndustryCategory,
      loading,
      agree
    } = this.state;
    if (workemail !== "" && nexstep) {
      return (
        <Fragment>
          <Form.Group widths="equal">
            <Form.Field>
              <label>First Name</label>
              <Input
                onChange={this.handleOnChange}
                name="firstName"
                placeholder="First Name"
              />
            </Form.Field>
            <Form.Field>
              <label>Last Name</label>
              <Input
                onChange={this.handleOnChange}
                name="lastName"
                placeholder="Last Name"
              />
            </Form.Field>
          </Form.Group>
          <Fragment>
            <Form.Field>
              <label>Department</label>
              <Input
                value={this.state.departmentName}
                placeholder="Department"
                name="departmentName"
                onChange={this.handleOnChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Company</label>
              <Input
                value={this.state.companyName}
                placeholder="Company"
                name="companyName"
                onChange={this.handleOnChange}
              />
            </Form.Field>
          </Fragment>
          <Form.Field>
            <label>Location</label>
            <Input
              value={this.state.location}
              placeholder="Location"
              name="location"
              onChange={this.handleOnChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Industry Category</label>
            <Dropdown
              search
              selection
              allowAdditions
              value={industryCategory}
              options={options}
              placeholder="Choose or type you're Category"
              onChange={this.handleChange}
            />
          </Form.Field>
          {industryCategory ===
          "Other (Specify industry in the field below)" ? (
            <Form.Field>
              <label>Other Industry Category</label>
              <Input
                value={otherIndustryCategory}
                placeholder="Type you're Category"
                name="otherIndustryCategory"
                onChange={this.handleOnChange}
              />
            </Form.Field>
          ) : null}
          <Form.Field>
            <label>Job title</label>
            <Input
              value={this.state.jobTitle}
              placeholder="Job title"
              name="jobTitle"
              onChange={this.handleOnChange}
            />
          </Form.Field>
          <Button
            color="purple"
            type="submit"
            onClick={() => {
              this.updateTheUser(() => {
                this.routeTo("login");
              });
            }}
          >
            Submit
          </Button>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Form.Field>
            <label>Work Email</label>
            <Input
              onChange={this.handleOnChange}
              name="workemail"
              placeholder="Work Email"
            />
          </Form.Field>
          <Form.Field>
            <label>
              Password{" "}
              <Popup trigger={<Icon name="attention" color="yellow" />}>
                <Header as="h2" content="Password Policy" />
                <p>
                  Specifying a minimum password length of at least 8 characters,
                  as well as requiring uppercase, numeric, and special
                  characters.
                </p>
              </Popup>
            </label>
            <Input
              type="password"
              onChange={this.handleOnChange}
              name="password"
              placeholder="Password"
            />
          </Form.Field>
          <Form.Field>
            <label>
              Confirm Password{" "}
              <Popup trigger={<Icon name="attention" color="yellow" />}>
                <Header as="h2" content="Password Policy" />
                <p>
                  Specifying a minimum password length of at least 8 characters,
                  as well as requiring uppercase, numeric, and special
                  characters.
                </p>
              </Popup>
            </label>
            <Input
              type="password"
              onChange={this.handleOnChange}
              name="confirmpassword"
              placeholder="Confirm Password"
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label={
                <label>
                  I accept the{" "}
                  <a href="/terms" target="_blank">
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a href="/policy" target="_blank">
                    Privacy Policy
                  </a>
                </label>
              }
              onChange={this.toggle}
            />
          </Form.Field>
          <Button
            disabled={!agree}
            color="purple"
            type="submit"
            loading={loading}
            onClick={() => {
              if (!loading) {
                this.cognitoRegisterUser();
              }
            }}
          >
            Next
          </Button>
        </Fragment>
      );
    }
  };

  renderError = () => {
    let { errorMessage } = this.state;
    return errorMessage ? (
      <Message negative>
        <Message.Header>Error</Message.Header>
        <p>{errorMessage}</p>
      </Message>
    ) : null;
  };

  render() {
    return (
      <Grid
        padded
        style={{
          backgroundImage: `url(/assets/home_background.svg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <GridRow>
          <GridColumn
            computer={5}
            tablet={4}
            mobile={2}
            widescreen={6}
          ></GridColumn>
          <GridColumn computer={6} tablet={8} mobile={12} widescreen={4}>
            <Segment>
              <Grid>
                <GridRow>
                  <GridColumn width={3}></GridColumn>
                  <GridColumn width={10}>
                    <Image
                      src="assets/400dpiLogo.png"
                      srcSet="assets/400dpiLogo@2x.png"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.routeTo("home");
                      }}
                    />
                  </GridColumn>
                  <GridColumn width={3}></GridColumn>
                </GridRow>
                <GridRow>
                  <GridColumn width={16}>
                    <Form>{this.registrationForm()}</Form>
                  </GridColumn>
                </GridRow>
                <GridRow>
                  <GridColumn width={16}>{this.renderError()}</GridColumn>
                </GridRow>
                <GridRow>
                  <GridColumn width={4}></GridColumn>
                  <GridColumn width={8}>
                    <Link to="/login">
                      <Container textAlign="center">
                        Already have an account
                      </Container>
                    </Link>
                  </GridColumn>
                  <GridColumn width={4}></GridColumn>
                </GridRow>
              </Grid>
            </Segment>
          </GridColumn>
          <GridColumn
            computer={5}
            tablet={4}
            mobile={2}
            widescreen={6}
          ></GridColumn>
        </GridRow>
      </Grid>
    );
  }
}

let mapStateToProps = (state, props) => {
  return {};
};

let mapDispatchToProps = dispatch => {
  return {
    updateUser: (data, cb) => {
      dispatch(UpdateUser(data));
      cb();
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Registration));
