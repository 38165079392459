import {
  GET_USERS,
  UPDATE_USER,
  LOADING,
  ERROR_USER,
  CLEAR_USER_STATE
} from "../Constants/Users";

const initialState = {
  user: null,
  loading: false,
  error: false,
  errorMessage: ""
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_USERS:
      return { ...state, user: payload };
    case UPDATE_USER:
      return { ...state, user: { ...state.user, ...payload } };
    case LOADING:
      return { ...state, loading: payload };
    case CLEAR_USER_STATE:
      return { ...initialState };
    case ERROR_USER:
      return { ...state, error: payload, errorMessage: "" };
    default:
      return state;
  }
};
