import axios from "axios";
import { getIdToken } from "../Util/Auth";
import {
  GET_USERS,
  UPDATE_USER,
  ERROR_USER,
  LOADING,
  CLEAR_USER_STATE
} from "../Constants/Users";

let baseUrl = process.env.REACT_APP_BASE_URL;

export const GetUser = () => async dispatch => {
  axios
    .get(`${baseUrl}/users/`, {
      headers: { Authorization: await getIdToken() }
    })
    .then(data => dispatch({ type: GET_USERS, payload: data.data }))
    .catch(error => dispatch({ type: ERROR_USER, payload: error }));
};

export const UpdateUser = data => async dispatch => {
  axios
    .put(`${baseUrl}/users`, data, {
      headers: { Authorization: await getIdToken() }
    })
    .then(data => dispatch({ type: UPDATE_USER, payload: data.data }))
    .catch(error => dispatch({ type: ERROR_USER, payload: error }));
};

export const ClearUser = () => async dispatch => {
  dispatch({ type: CLEAR_USER_STATE });
};
