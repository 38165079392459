import React, { Component, Fragment } from "react";
import {
  Dimmer,
  Grid,
  Icon,
  Step,
  GridRow,
  GridColumn,
  Button,
  Modal,
  Message,
  Header,
  Pagination,
  Segment,
  List,
  Label,
  Container,
  Placeholder
} from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import GuageChart from "../Components/GuageChart";
import { StripeProvider, Elements } from "react-stripe-elements";
import {
  ListAllAssesment,
  CreateAssesment,
  GetAssesment,
  GetAssesmentQuestion,
  UpdateAssesment,
  UpdateAssesmentQuestion,
  UpdateAssesmentPayment,
  GetMoreInfo
} from "../Actions/AssesmentActions";
import { ListAllCategories } from "../Actions/CategoriesActions";
import { ListAllQuestions } from "../Actions/QuestionsActions";
import AssesmentMenu from "../Components/AssesmentMenu";
import AssesmentResult from "../Components/AssesmentResult";
import AssementAnswer from "../Components/AssementAnswer";
import AssesmentPay from "../Components/AssesmentPay";
import { withRouter } from "react-router-dom";
import { Logger } from "@aws-amplify/core";

class Assesment extends Component {
  state = {
    type: "payment",
    loaded: false,
    activeCategory: 1,
    activePage: 1,
    activeCategoryName: "",
    modalOpen: false
  };

  checkDraft = params => {
    this.props.getAssesment({
      assessmentId: params.assessmentId
    });
    if (params) {
      if (_.isEqual(_.get(params, "paymentStatus"), "SUCCESS")) {
        this.onStepClick("qa");
      } else {
        this.onStepClick("payment");
      }
      this.props.getAssesmentQuestion({
        assessmentId: params.assessmentId
      });
    }
  };

  getDraftPendingCategoryQuestion = (AssesQuestions, categoryIndex) => {
    let pendingQuestions = AssesQuestions.filter(question =>
      _.isUndefined(question.answer)
    );
    let pendingQuestion = _.head(pendingQuestions);

    let categoryName = _.find(_.get(this.props, "categorySort"), {
      categorySortId: pendingQuestion.categorySortId
    }).categoryName;

    return {
      activePage: pendingQuestion.questionNumber,
      activeCategory: pendingQuestion.categorySortId,
      activeCategoryName: categoryName,
      pendingQuestions: pendingQuestions
    };
  };

  setDraftCategoryQuestion = (lastCategoryId, lastQuestionId) => {
    let AssesQuestions = _.get(this.props, "assesQuestions");

    let category = AssesQuestions.find(
      category =>
        category.categoryId === lastCategoryId &&
        category.questionId === lastQuestionId
    );

    let categoryIndex = AssesQuestions.findIndex(
      category =>
        category.categoryId === lastCategoryId &&
        category.questionId === lastQuestionId
    );

    this.setState(
      prevState => {
        if (prevState.activeCategory !== category.categorySortId) {
          let categoryName = _.find(_.get(this.props, "categorySort"), {
            categorySortId: category.categorySortId
          }).categoryName;

          return {
            activeCategory: category.categorySortId,
            activeCategoryName: categoryName
          };
        } else if (prevState.activePage !== category.questionNumber) {
          let categoryName = _.find(_.get(this.props, "categorySort"), {
            categorySortId: category.categorySortId
          }).categoryName;

          return {
            activePage: category.questionNumber + 1,
            activeCategoryName: category.categoryName,
            loaded: true
          };
        }
      },
      () => {
        let daft = this.getDraftPendingCategoryQuestion(
          AssesQuestions,
          categoryIndex
        );
        this.setState({
          activePage: daft.activePage,
          activeCategory: daft.activeCategory,
          activeCategoryName: daft.activeCategoryName
        });
      }
    );
  };

  componentDidMount() {
    let params = _.get(this.props, "location.state", null);
    if (params) {
      this.checkDraft(params);
    }
  }

  routeTo = (route, state) => {
    this.props.history.push(`/${route}`, state);
  };

  onStepClick = type => {
    this.setState({
      type: type
    });
  };

  getQuestion = () => {
    this.props.getAssesmentQuestion({
      assessmentId: this.props.assessmentId
    });
  };

  makepaymnet = paymentToken => {
    this.props.updateAssesmentPayment(
      {
        assessmentId: this.props.assessmentId,
        paymentToken: paymentToken
      },
      () => {
        this.onStepClick("qa");
      }
    );
  };

  componentDidUpdate() {
    if (this.props.paystatus === "SUCCESS") {
      this.getQuestion();
    }
    let params = _.get(this.props, "location.state", null);
    if (
      params &&
      !_.isEmpty(_.get(this.props, "assesQuestions", [])) &&
      !this.state.loaded
    ) {
      if (params.lastCategoryId && params.lastQuestionId) {
        this.setDraftCategoryQuestion(
          params.lastCategoryId,
          params.lastQuestionId
        );
      }
    }
  }

  getQuestionCount = assesQuestions => {
    return _.get(assesQuestions, "length", 0);
  };

  handlePaginationChange = (e, { activePage }) => {
    let categorySortId = _.find(_.get(this.props, "assesQuestions"), {
      questionNumber: activePage
    }).categorySortId;
    let categoryName = _.find(_.get(this.props, "categorySort"), {
      categorySortId: categorySortId
    }).categoryName;
    this.setState({
      activePage: activePage,
      activeCategory: categorySortId,
      activeCategoryName: categoryName
    });
  };

  getQuestionInCategory = (assesQuestions, activeCategory, activePage) => {
    return _.find(assesQuestions, {
      categorySortId: activeCategory,
      questionNumber: activePage
    });
  };

  selectCategory = no => {
    let questionNumber = _.find(_.get(this.props, "assesQuestions"), {
      categorySortId: no
    }).questionNumber;
    this.setState({
      activeCategory: no,
      activePage: questionNumber
    });
  };

  markAnswer = (questionId, categoryId, assessmentId, data) => {
    let { activePage, activeCategory } = this.state;
    let { assesQuestions } = this.props;
    if (!_.isEmpty(assesQuestions)) {
      this.props.updateAssesmentQuestion({
        categoryId: categoryId,
        assessmentId: assessmentId,
        questionId: questionId,
        answer: data,
        createdOn: moment().format()
      });
    }
    this.setState((prevState, props) => {
      if (prevState.activePage < this.getQuestionCount(assesQuestions)) {
        let categorySortId = _.find(_.get(this.props, "assesQuestions"), {
          questionNumber: activePage + 1
        }).categorySortId;
        let categoryName = _.find(_.get(this.props, "categorySort"), {
          categorySortId: categorySortId
        }).categoryName;

        return {
          activePage: activePage + 1,
          activeCategory: categorySortId,
          activeCategoryName: categoryName
        };
      } else if (
        prevState.activePage === this.getQuestionCount(assesQuestions)
      ) {
        let categoryName = _.find(_.get(this.props, "categorySort"), {
          categorySortId: prevState.activeCategory
        }).categoryName;
        return {
          activePage: prevState.activePage,
          activeCategory: prevState.activeCategory,
          activeCategoryName: categoryName
        };
      } else {
        let categoryName = _.find(_.get(this.props, "categorySort"), {
          categorySortId: prevState.activeCategory + 1
        }).categoryName;
        return {
          activePage: 1,
          activeCategory: prevState.activeCategory + 1,
          activeCategoryName: categoryName
        };
      }
    });
  };

  completeAssesment = (status, cb) => {
    this.props.updateAssesment({
      assessmentId: this.props.assessmentId,
      status: _.isNull(status) ? "COMPLETED" : status,
      [_.isNull(status) ? "completedOn" : "updatedOn"]: moment().format()
    });
    cb();
  };

  TakeRef = data => {
    this.setState({ form: data });
  };

  checkStep = type => this.state[type] === type;

  handleCategoryClick = (e, { name }) =>
    this.setState({ activeCategoryName: name });

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  renderStep = type => {
    let { assesQuestions } = this.props;
    let { activeCategory, activePage } = this.state;
    let Questions = this.getQuestionInCategory(
      assesQuestions,
      activeCategory,
      activePage
    );
    switch (type) {
      case "qa":
        return (
          <Fragment>
            <GridRow style={{ height: "60vh" }}>
              <GridColumn computer={2} tablet={1} mobile={1}></GridColumn>
              <GridColumn computer={3} tablet={5} mobile={15}>
                {!_.isEmpty(this.props.assesQuestions) ? (
                  <AssesmentMenu
                    handleCategoryClick={this.handleCategoryClick}
                    activeCategoryName={this.state.activeCategoryName}
                    categories={this.props.categorySort}
                    selectCategory={this.selectCategory}
                  />
                ) : null}
              </GridColumn>
              <GridColumn computer={8} tablet={9} mobile={16}>
                <AssementAnswer
                  getMoreInfo={this.props.getMoreInfo}
                  moreinfo={this.props.moreinfo}
                  question={Questions}
                  markAnswer={this.markAnswer}
                  getCategoyProgress={_.get(this.props, "progress")}
                />
              </GridColumn>
              <GridColumn computer={3} tablet={1} mobile={1}></GridColumn>
              <GridColumn computer={7} tablet={5} mobile={1}></GridColumn>
              <GridColumn computer={8} tablet={10} mobile={12}>
                {!_.isEmpty(this.props.assesQuestions) ? (
                  <Pagination
                    activePage={this.state.activePage}
                    boundaryRange={1}
                    siblingRange={1}
                    totalPages={this.getQuestionCount(
                      this.props.assesQuestions
                    )}
                    onPageChange={this.handlePaginationChange}
                  />
                ) : null}
              </GridColumn>
              <GridColumn computer={1} tablet={1} mobile={2}></GridColumn>
              {_.get(this.props, "location.state.status") === "DRAFT" &&
              !_.isEmpty(this.props.assesQuestions) ? (
                <Fragment>
                  <GridColumn computer={5} tablet={5}></GridColumn>
                  <GridColumn computer={6} tablet={6}>
                    <Message negative size="tiny" style={{ marginTop: "10px" }}>
                      <Message.Header>
                        You have unattended questions in Categories
                      </Message.Header>
                      <p>Please attend them to move forward</p>
                    </Message>
                  </GridColumn>
                  <GridColumn computer={5} tablet={5}></GridColumn>
                </Fragment>
              ) : null}
            </GridRow>
            <GridRow>
              <GridColumn computer={2} tablet={1} mobile={1} />
              <GridColumn computer={12} tablet={14} mobile={14}>
                <Button
                  floated="left"
                  onClick={() => {
                    if (
                      _.isEqual(_.get(this.props, "paymentStatus"), "SUCCESS")
                    ) {
                      this.routeTo("main", null);
                    } else {
                      this.onStepClick("payment");
                    }
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  disabled={!_.isEqual(_.get(this.props, "progress"), 100)}
                  floated="right"
                  color="green"
                  onClick={() => {
                    this.completeAssesment(null, () => {
                      this.onStepClick("readiness");
                    });
                  }}
                >
                  RESULTS
                </Button>
                <Modal
                  trigger={
                    <Button
                      disabled={_.isEqual(_.get(this.props, "progress"), 100)}
                      onClick={this.handleOpen}
                      floated="right"
                      color="teal"
                    >
                      SAVE AS DRAFT
                    </Button>
                  }
                  open={this.state.modalOpen}
                  onClose={this.handleClose}
                  basic
                  size="small"
                >
                  <Header icon="save outline" content="Save as Draft" />
                  <Modal.Content>
                    <h3>Are you sure you want to save this as draft?.</h3>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      basic
                      onClick={this.handleClose}
                      color="red"
                      inverted
                    >
                      <Icon name="remove" /> No
                    </Button>
                    <Button
                      color="green"
                      onClick={() => {
                        this.handleClose();
                        this.routeTo("main");
                      }}
                      inverted
                    >
                      <Icon name="checkmark" /> Yes
                    </Button>
                  </Modal.Actions>
                </Modal>
              </GridColumn>
              <GridColumn computer={2} tablet={1} mobile={1} />
            </GridRow>
          </Fragment>
        );
      case "readiness":
        return (
          <Fragment>
            <AssesmentResult
              categoryScores={this.props.categoryScores}
              totalScore={_.get(this.props, "totalScore")}
              location={_.get(this.props, "location.state.from")}
            />
            <GridRow>
              <GridColumn width={2} />
              <GridColumn width={12}>
                <Button
                  floated="left"
                  onClick={() => {
                    this.onStepClick("qa");
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  floated="right"
                  color="green"
                  onClick={() => {
                    this.routeTo(
                      `report/${_.get(this.props, "assessmentId")}`,
                      { from: "assesment" }
                    );
                  }}
                >
                  FINISH
                </Button>
              </GridColumn>
              <GridColumn width={2} />
            </GridRow>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <GridRow>
              <GridColumn width={4}></GridColumn>
              <GridColumn width={8}>
                Enter your billing details below
              </GridColumn>
              <GridColumn width={4}></GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={4}></GridColumn>
              <GridColumn width={8}>
                <Dimmer.Dimmable
                  blurring
                  dimmed={_.isEqual(
                    _.get(this.props, "paymentStatus"),
                    "SUCCESS"
                  )}
                  style={{ borderRadius: ".28571429rem" }}
                >
                  <Dimmer
                    active={_.isEqual(
                      _.get(this.props, "paymentStatus"),
                      "SUCCESS"
                    )}
                    verticalAlign="top"
                  >
                    <Header as="h2" inverted>
                      Payment Successful!
                    </Header>
                    <Icon name="check circle outline" size="massive"></Icon>
                  </Dimmer>
                  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
                    <Elements>
                      <AssesmentPay
                        makepaymnet={this.makepaymnet}
                        takeRef={this.TakeRef}
                      />
                    </Elements>
                  </StripeProvider>
                </Dimmer.Dimmable>
              </GridColumn>
              <GridColumn width={4}></GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={2} />
              <GridColumn width={12}>
                <Button
                  floated="left"
                  onClick={() => {
                    this.routeTo("main", null);
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  floated="right"
                  color="green"
                  onClick={event => {
                    if (
                      _.isEqual(_.get(this.props, "paymentStatus"), "SUCCESS")
                    ) {
                      this.onStepClick("qa");
                    } else {
                      this.state.form.handleSubmit(event);
                    }
                  }}
                >
                  {_.isEqual(_.get(this.props, "paymentStatus"), "SUCCESS")
                    ? "NEXT"
                    : "PAY $ 4950"}
                </Button>
              </GridColumn>
              <GridColumn width={2} />
            </GridRow>
          </Fragment>
        );
    }
  };

  render() {
    return (
      <Grid>
        <GridRow></GridRow>
        <GridRow>
          <GridColumn computer={2} tablet={1} mobile={1} />
          <GridColumn computer={12} tablet={14} tablet={14}>
            <Step.Group fluid attached="top">
              <Step
                active={this.checkStep("payment")}
                onClick={() => {
                  this.onStepClick("payment");
                }}
                completed={_.isEqual(
                  _.get(this.props, "paymentStatus"),
                  "SUCCESS"
                )}
              >
                <Icon name="payment" />
                <Step.Content>
                  <Step.Title>Payment</Step.Title>
                  <Step.Description>Pay for the assessment</Step.Description>
                </Step.Content>
              </Step>
              <Step
                active={this.checkStep("qa")}
                onClick={() => {
                  this.onStepClick("qa");
                }}
                completed={_.isEqual(_.get(this.props, "progress"), 100)}
              >
                <Icon name="truck" />
                <Step.Content>
                  <Step.Title>Q & A</Step.Title>
                  <Step.Description>Answer all questions</Step.Description>
                </Step.Content>
              </Step>
              <Step
                active={this.checkStep("readiness")}
                onClick={() => {
                  this.onStepClick("readiness");
                }}
              >
                <Icon name="info" />
                <Step.Content>
                  <Step.Title>Results</Step.Title>
                  <Step.Description>View your results</Step.Description>
                </Step.Content>
              </Step>
            </Step.Group>
          </GridColumn>
          <GridColumn computer={2} tablet={1} mobile={1} />
        </GridRow>
        {this.renderStep(this.state.type)}
      </Grid>
    );
  }
}

let mapStateToProps = (state, props) => {
  return {
    moreinfo: state.assesment.moreinfo,
    paystatus: state.assesment.paystatus,
    paymentStatus: state.assesment.paymentStatus,
    progress: state.assesment.progress,
    categoryScores: state.assesment.categoryScores,
    totalScore: state.assesment.totalScore,
    paymentstatus: state.assesment.paymentstatus,
    assessmentId: state.assesment.assessmentId,
    assesments: state.assesment.assesments,
    assesment: state.assesment.assesment,
    assesQuestions: state.assesment.assesQuestions,
    categorySort: state.assesment.categorySort,
    categories: state.category.categories,
    questions: state.question.questions,
    loading: state.assesment.loading,
    totalQuestions: state.assesment.totalQuestions
  };
};

let mapDispatchToProps = dispatch => {
  return {
    listAllAssesment: () => {
      dispatch(ListAllAssesment());
    },
    createAssesment: data => {
      dispatch(CreateAssesment(data));
    },
    getAssesment: data => {
      dispatch(GetAssesment(data));
    },
    getAssesmentQuestion: data => {
      dispatch(GetAssesmentQuestion(data));
    },
    updateAssesment: data => {
      dispatch(UpdateAssesment(data));
    },
    updateAssesmentQuestion: data => {
      dispatch(UpdateAssesmentQuestion(data));
    },
    updateAssesmentPayment: (data, cb) => {
      dispatch(UpdateAssesmentPayment(data));
      cb();
    },
    listAllCategories: () => {
      dispatch(ListAllCategories());
    },
    listAllQuestions: () => {
      dispatch(ListAllQuestions());
    },
    getMoreInfo: (categoryId, questionId) => {
      dispatch(GetMoreInfo(categoryId, questionId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Assesment));
