import React, { Component } from "react";
import {
  GridRow,
  GridColumn,
  Grid,
  Button,
  Card,
  Container,
  CardGroup,
  Header,
  Placeholder,
  Message
} from "semantic-ui-react";
import { connect } from "react-redux";
import _ from "lodash";
import AssesmentCard from "../Components/AssesmentCard";
import {
  ListAllAssesment,
  CreateAssesment,
  SelectAssesment
} from "../Actions/AssesmentActions";
import { GetUser } from "../Actions/UsersActions";
import { withRouter } from "react-router-dom";

class Main extends Component {
  routeTo = (route, state) => {
    this.props.history.push(`/${route}`, state);
  };

  componentDidMount() {
    if (_.isNil(_.get(this.props, "user"))) {
      this.props.getUser();
    }
    this.props.listAllAssesment();
  }

  startAssesment = () => {
    this.props.createAssesment();
    this.routeTo("assesment", null);
  };

  getReport = assessmentId => {
    this.routeTo(`report/${assessmentId}`, null);
  };

  getAssesment = data => {
    this.props.selectAssesment(data.assessmentId);
    this.routeTo(`assesment`, data);
  };

  renderAssesmentCards = (assesments, loading) => {
    if (!_.isEmpty(assesments) && !loading) {
      return assesments.map(assesment => {
        return (
          <AssesmentCard
            key={assesment.id}
            id={assesment.id}
            total={assesment.totalScore}
            totalQuestions={assesment.totalQuestions}
            totalPercentage={Math.floor(
              (assesment.totalScore / assesment.totalQuestions) * 100
            )}
            status={assesment.status}
            getReport={this.getReport}
            getAssesment={this.getAssesment}
            lastCategoryId={assesment.lastCategoryId}
            lastQuestionId={assesment.lastQuestionId}
            paymentStatus={assesment.paymentStatus}
            updatedOn={assesment.updatedOn}
            createdOn={assesment.createdOn}
            createdOnFormatted={assesment.createdOn}
          />
        );
      });
    } else if (_.isEmpty(assesments) && !loading) {
      return (
        <Message negative>
          <Message.Header>Haven't started an Assessment yet?</Message.Header>
          <p>No Assessments Available</p>
        </Message>
      );
    } else if (loading) {
      return (
        <CardGroup stackable>
          {[{ key: 1 }, { key: 2 }, { key: 3 }, { key: 4 }].map(card => {
            return (
              <Card
                key={card.key}
                style={{
                  width: "290px",
                  height: "327.875px"
                }}
              >
                <Placeholder>
                  <Placeholder.Image square />
                </Placeholder>
                <Card.Content>
                  <Placeholder>
                    <Placeholder.Header>
                      <Placeholder.Line length="very short" />
                      <Placeholder.Line length="medium" />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length="short" />
                    </Placeholder.Paragraph>
                  </Placeholder>
                </Card.Content>
              </Card>
            );
          })}
        </CardGroup>
      );
    }
  };

  render() {
    const { assesments, loading } = this.props;
    return (
      <Grid style={{ width: "100vw" }}>
        <GridRow></GridRow>
        <GridRow></GridRow>
        <GridRow>
          <Container text textAlign="center">
            <Header
              as="h2"
              content="Start an Assessment and answer a series of questions to check the readiness of your company to invest in Artificial Intelligence"
            ></Header>
          </Container>
        </GridRow>
        <GridRow></GridRow>
        <GridRow>
          <GridColumn computer={6} mobile={4} tablet={5}></GridColumn>
          <GridColumn computer={4} mobile={8} tablet={6}>
            <Button
              color="purple"
              fluid
              onClick={() => {
                this.startAssesment();
              }}
            >
              START NEW ASSESSMENT
            </Button>
          </GridColumn>
          <GridColumn computer={6} mobile={4} tablet={5}></GridColumn>
        </GridRow>
        <GridRow></GridRow>
        <GridRow>
          <GridColumn width={1} />
          <GridColumn width={14}>
            <Header as="h2">Previous Assessments</Header>
          </GridColumn>
          <GridColumn width={1} />
        </GridRow>
        <GridRow>
          <GridColumn width={16}>
            <CardGroup centered>
              {this.renderAssesmentCards(assesments, loading)}
            </CardGroup>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  }
}

let mapStateToProps = (state, props) => {
  return {
    user: state.user.user,
    assesments: state.assesment.assesments,
    loading: state.assesment.loading
  };
};

let mapDispatchToProps = dispatch => {
  return {
    getUser: () => {
      dispatch(GetUser());
    },
    listAllAssesment: () => {
      dispatch(ListAllAssesment());
    },
    createAssesment: () => {
      dispatch(CreateAssesment());
    },
    selectAssesment: data => {
      dispatch(SelectAssesment(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Main));
