import React, { Component } from "react";
import {
  Breadcrumb,
  Grid,
  GridRow,
  GridColumn,
  Header,
  Menu,
  Message,
  Label,
  Segment,
  Placeholder
} from "semantic-ui-react";
import {
  GetAssesment,
  GetAssesmentQuestion,
  GetMoreInfo
} from "../Actions/AssesmentActions";
import { ListAllCategories } from "../Actions/CategoriesActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import GuageChart from "../Components/GuageChart";
import MoreInfo from "../Components/MoreInfo";
import { withToastManager } from "react-toast-notifications";

class Report extends Component {
  state = {
    activeItem: "Business",
    sortedQuestions: [],
    loadcat: false,
    loadcatname: false
  };

  componentDidMount() {
    const assessmentId = _.get(this.props, "match.params.assessmentId");
    this.props.getAssesment({ assessmentId }, () => {
      this.setState({ loadcat: true });
    });
    if (_.isEqual(_.get(this.props, "location.state.from"), "assesment")) {
      this.props.toastManager.add(this.toastMessage(), {
        appearance: "warning",
        autoDismiss: true,
        pauseOnHover: true
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEmpty(_.get(props, "categoryScores")) && state.loadcat) {
      const assessmentId = _.get(props, "match.params.assessmentId");
      const categoryId = _.find(_.get(props, "categoryScores"), {
        categoryName: "Business"
      }).categoryId;
      props.getAssesmentQuestion({ assessmentId, categoryId });
      return { loadcat: false, loadcatname: true };
    } else {
      return null;
    }
  }

  componentDidUpdate() {
    if (
      !_.isEmpty(_.get(this.props, "assesQuestionsReport")) &&
      this.state.loadcatname
    ) {
      let categoryId = _.head(_.get(this.props, "assesQuestionsReport"))
        .categoryId;
      let categoryName = _.find(_.get(this.props, "categoryScores"), {
        categoryId: categoryId
      }).categoryName;
      this.setState({ activeItem: categoryName, loadcatname: false });
    }
  }

  toastMessage = () => {
    return (
      <div>
        <strong>Please check your email!</strong>
        <div>
          A detailed action plan report has been sent via email, Please check
          and make any improvements on the assessment if necessary
        </div>
      </div>
    );
  };

  routeTo = route => {
    this.props.history.push(`/${route}`);
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  setCategoryId = categoryId => {
    const assessmentId = _.get(this.props, "match.params.assessmentId");
    this.props.getAssesmentQuestion({ assessmentId, categoryId });
  };

  renderCategoryItems = (categories, activeItem) => {
    if (!_.isEmpty(categories)) {
      return categories.map(category => {
        return (
          <Menu.Item
            key={category.categoryId}
            name={category.categoryName}
            active={category.categoryName === activeItem}
            onClick={(e, c) => {
              this.handleItemClick(e, c);
              this.setCategoryId(category.categoryId);
            }}
          >
            <Label color="teal">{category.score}</Label>
            {category.categoryName}
          </Menu.Item>
        );
      });
    } else {
      return (
        <Menu.Item
          name="No Categories available"
          onClick={this.handleItemClick}
        />
      );
    }
  };

  renderCategoryItemAnswers = categoyQuestions => {
    if (!_.isEmpty(categoyQuestions)) {
      return categoyQuestions.map(question => {
        return (
          <Segment key={question.questionId}>
            <Header as="h4">
              <Grid>
                <GridRow>
                  <GridColumn width={14}>
                    <div>
                      {_.get(question, "questionNumber", null)}. {"\u00A0"}
                      {_.get(question, "question.title", null)}
                      {"\u00A0"} You answered {"\u00A0"}
                      {_.get(question, "answer", null) === "IN_PROGRESS" ? (
                        <Label horizontal color="orange">
                          WORKING IN PROGRESS
                        </Label>
                      ) : (
                        <Label horizontal color="red">
                          {_.get(question, "answer", null)}
                        </Label>
                      )}
                    </div>
                  </GridColumn>
                  <GridColumn width={2}></GridColumn>

                  {true ? (
                    <GridColumn width={16}>
                      <MoreInfo
                        no={_.get(question, "questionNumber", null)}
                        questionId={question.questionId}
                        categoryId={question.categoryId}
                        moreinfo={this.props.moreinfo}
                        getMoreInfo={this.props.getMoreInfo}
                        quiz={_.get(question, "question.title", null)}
                      />
                    </GridColumn>
                  ) : null}
                </GridRow>
              </Grid>
            </Header>
          </Segment>
        );
      });
    } else {
      return (
        <Segment>
          <Header as="h4">
            No Questions available to show because you answered all Yes in this
            Category
          </Header>
        </Segment>
      );
    }
  };

  getCategoryQuestion = (categoyQuestions, activeItem) => {
    return _.get(
      _.find(categoyQuestions, { categoryName: activeItem }),
      "questions"
    );
  };

  getCategoryQuestionResult = (categoyQuestions, activeItem) => {
    let category = _.find(categoyQuestions, { categoryName: activeItem });
    return {
      yes: (
        <Label size="mini" color="green">
          {_.get(category, "yesCount", 0)}
        </Label>
      ),
      no: (
        <Label size="mini" color="red">
          {_.get(category, "noCount", 0)}
        </Label>
      ),
      wip: (
        <Label size="mini" color="orange">
          {_.get(category, "inProgressCount", 0)}
        </Label>
      ),
      total: (
        <Label size="mini" color="grey">
          {_.get(category, "score", 0)}
        </Label>
      )
    };
  };

  render() {
    const { activeItem } = this.state;
    let { categoryScores, assesQuestionsReport } = this.props;
    return (
      <Grid padded>
        <GridRow>
          <GridColumn width={16}>
            <Breadcrumb>
              <Breadcrumb.Section
                link
                onClick={() => {
                  this.routeTo("main");
                }}
              >
                Home
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right chevron" />
              <Breadcrumb.Section link>Report</Breadcrumb.Section>
            </Breadcrumb>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn width={16}>
            <Segment>
              <Header as="h2">Assessment Report</Header>
            </Segment>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn computer={4} mobile={3} />
          <GridColumn computer={8} mobile={10}>
            {_.get(this.props, "totalScore") ? (
              <GuageChart total={_.get(this.props, "totalScore")} />
            ) : (
              <Placeholder className="haflcircle">
                <Placeholder.Image />
              </Placeholder>
            )}
          </GridColumn>
          <GridColumn computer={4} mobile={3}></GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn width={16}>
            <Header as="h2">Action Plan</Header>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn computer={3} mobile={9} tablet={5}>
            <Menu pointing vertical>
              {this.renderCategoryItems(categoryScores, activeItem)}
            </Menu>
          </GridColumn>
          <GridColumn computer={13} mobile={7} tablet={11}>
            <Segment.Group>
              <Segment.Group horizontal>
                <Segment>
                  <Header color="green">
                    Yes{" "}
                    {
                      this.getCategoryQuestionResult(categoryScores, activeItem)
                        .yes
                    }
                  </Header>
                </Segment>
                <Segment>
                  <Header color="red">
                    No{" "}
                    {
                      this.getCategoryQuestionResult(categoryScores, activeItem)
                        .no
                    }
                  </Header>
                </Segment>
                <Segment>
                  <Header color="orange">
                    Work In Progress{" "}
                    {
                      this.getCategoryQuestionResult(categoryScores, activeItem)
                        .wip
                    }
                  </Header>
                </Segment>
                <Segment>
                  <Header color="grey">
                    Total{" "}
                    {
                      this.getCategoryQuestionResult(categoryScores, activeItem)
                        .total
                    }
                  </Header>
                </Segment>
              </Segment.Group>

              {this.renderCategoryItemAnswers(
                this.getCategoryQuestion(assesQuestionsReport, activeItem)
              )}
            </Segment.Group>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  }
}

let mapStateToProps = (state, props) => {
  return {
    moreinfo: state.assesment.moreinfo,
    categoryScores: state.assesment.categoryScores,
    categories: state.category.categories,
    totalAnswered: state.assesment.totalAnswered,
    totalScore: state.assesment.totalScore,
    totalQuestions: state.assesment.totalQuestions,
    assessmentId: state.assesment.assessmentId,
    assesQuestionsReport: state.assesment.assesQuestionsReport,
    loading: state.assesment.loading
  };
};

let mapDispatchToProps = dispatch => {
  return {
    getAssesment: (data, cb) => {
      dispatch(GetAssesment(data));
      cb();
    },
    getAssesmentQuestion: data => {
      dispatch(GetAssesmentQuestion(data));
    },
    listAllCategories: () => {
      dispatch(ListAllCategories());
    },
    getMoreInfo: (categoryId, questionId) => {
      dispatch(GetMoreInfo(categoryId, questionId));
    }
  };
};

export default withToastManager(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(Report))
);
