import axios from "axios";
import {
  LIST_ASSESMENTS,
  CREATE_ASSESMENT,
  GET_ASSESMENT,
  SELECT_ASSIGNMENT,
  UPDATE_ASSESMENT,
  UPDATE_ASSESMENT_PAYMENT,
  GET_ASSESMENT_QUESTION,
  UPDATE_ASSESMENT_QUESTION,
  ERROR_ASSESMENT,
  GET_MOREINFO,
  CLEAR_MOREINFO,
  LOADING
} from "../Constants/Assesments";
import { getIdToken } from "../Util/Auth";
import Analytics from "@aws-amplify/analytics";

let baseUrl = process.env.REACT_APP_BASE_URL;

export const ListAllAssesment = () => async dispatch => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(`${baseUrl}/assessments`, {
      headers: { Authorization: await getIdToken() }
    })
    .then(data => dispatch({ type: LIST_ASSESMENTS, payload: data.data }))
    .catch(error => dispatch({ type: ERROR_ASSESMENT, payload: error }));
};

export const CreateAssesment = () => async dispatch => {
  axios
    .post(
      `${baseUrl}/assessments`,
      {},
      {
        headers: { Authorization: await getIdToken() }
      }
    )
    .then(data => {
      dispatch({ type: CREATE_ASSESMENT, payload: data.data });
      Analytics.record({ name: "startAssessment" });
    })
    .catch(error => dispatch({ type: ERROR_ASSESMENT, payload: error }));
};

export const GetAssesment = ({ assessmentId }) => async dispatch => {
  axios
    .get(`${baseUrl}/assessments/${assessmentId}`, {
      headers: { Authorization: await getIdToken() }
    })
    .then(data => dispatch({ type: GET_ASSESMENT, payload: data.data }))
    .catch(error => dispatch({ type: ERROR_ASSESMENT, payload: error }));
};

export const UpdateAssesment = ({ assessmentId, status }) => async dispatch => {
  axios
    .put(
      `${baseUrl}/assessments/${assessmentId}`,
      { status: status },
      {
        headers: { Authorization: await getIdToken() }
      }
    )
    .then(data => {
      dispatch({ type: UPDATE_ASSESMENT, payload: data.data });
      Analytics.record({
        name: "finishAssessment",
        attributes: { status }
      });
    })
    .catch(error => dispatch({ type: ERROR_ASSESMENT, payload: error }));
};

export const GetAssesmentQuestion = data => async dispatch => {
  axios
    .get(`${baseUrl}/assessmentquestions`, {
      params: data,
      headers: { Authorization: await getIdToken() }
    })
    .then(data =>
      dispatch({ type: GET_ASSESMENT_QUESTION, payload: data.data })
    )
    .catch(error => dispatch({ type: ERROR_ASSESMENT, payload: error }));
};

export const UpdateAssesmentQuestion = ({
  categoryId,
  assessmentId,
  questionId,
  answer,
  createdOn
}) => async dispatch => {
  axios
    .put(
      `${baseUrl}/assessmentquestions/${assessmentId}/${questionId}`,
      { answer },
      {
        headers: { Authorization: await getIdToken() }
      }
    )
    .then(data =>
      dispatch({ type: UPDATE_ASSESMENT_QUESTION, payload: data.data })
    )
    .catch(error => dispatch({ type: ERROR_ASSESMENT, payload: error }));
};

export const UpdateAssesmentPayment = ({
  assessmentId,
  paymentToken
}) => async dispatch => {
  axios
    .put(
      `${baseUrl}/assessmentpayment/${assessmentId}`,
      { paymentToken: paymentToken.id },
      {
        headers: { Authorization: await getIdToken() }
      }
    )
    .then(data => {
      dispatch({ type: UPDATE_ASSESMENT_PAYMENT, payload: data.data });
      Analytics.record({ name: "payAssessment" });
    })
    .catch(error => dispatch({ type: ERROR_ASSESMENT, payload: error }));
};

export const GetMoreInfo = (categoryId, questionId) => async dispatch => {
  dispatch({ type: CLEAR_MOREINFO });
  axios
    .get(`${baseUrl}/questions/${categoryId}/${questionId}`, {
      headers: { Authorization: await getIdToken() }
    })
    .then(data => dispatch({ type: GET_MOREINFO, payload: data.data }))
    .catch(error => dispatch({ type: ERROR_ASSESMENT, payload: error }));
};

export const SelectAssesment = assessmentId => dispatch => {
  dispatch({ type: SELECT_ASSIGNMENT, payload: assessmentId });
};
