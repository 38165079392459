import React, { Component, Fragment } from "react";
import {
  GridRow,
  GridColumn,
  Grid,
  Input,
  Image,
  Form,
  Button,
  Checkbox,
  Header,
  Segment,
  Container
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { SignIn } from "../Util/Auth";
import Reset from "./Reset";
import { validateValue } from "../Util/Validate";

class Login extends Component {
  state = {
    errorMessage: null,
    loading: false,
    reset: false,
    unerror: false,
    pwerror: false
  };
  routeTo = route => {
    this.props.history.push(`/${route}`);
  };

  forgotPassword = () =>
    this.setState(state => {
      return { reset: !state.reset };
    });

  handleOnChange = (e, { name, value }) =>
    this.setState({
      [name]: value,
      errorMessage: null,
      unerror: false,
      pwerror: false
    });

  handleFieldError = (username, password) => {
    this.setState({
      unerror: validateValue(username, "Username"),
      pwerror: validateValue(password, "Password")
    });
  };

  signIn = () => {
    let { username, password } = this.state;
    if (username && password) {
      this.setState({ loading: true });
      SignIn({ username, password }, (error, success) => {
        this.setState({ loading: false });
        if (error) {
          this.setState({ errorMessage: error.message });
        } else {
          this.routeTo("main");
        }
      });
    } else {
      this.handleFieldError(username, password);
    }
  };

  render() {
    return (
      <Grid
        padded
        style={{
          height: "100vh",
          backgroundImage: `url(/assets/home_background.svg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <GridRow>
          <GridColumn
            computer={5}
            tablet={4}
            mobile={2}
            widescreen={6}
          ></GridColumn>
          <GridColumn computer={6} tablet={8} mobile={12} widescreen={4}>
            <Segment>
              <Grid padded>
                <GridRow>
                  <GridColumn width={3}></GridColumn>
                  <GridColumn width={10}>
                    <Image
                      src="assets/400dpiLogo.png"
                      srcSet="assets/400dpiLogo@2x.png"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.routeTo("home");
                      }}
                    />
                  </GridColumn>
                  <GridColumn width={3}></GridColumn>
                </GridRow>
                <GridRow>
                  <GridColumn width={16}>
                    <Form>
                      {!this.state.reset ? (
                        <Fragment>
                          <Form.Field>
                            <Form.Input
                              label="Username"
                              placeholder="Username"
                              name="username"
                              error={this.state.unerror}
                              onChange={this.handleOnChange}
                            />
                          </Form.Field>
                          <Form.Field>
                            <Form.Input
                              label="Password"
                              type="password"
                              placeholder="Password"
                              name="password"
                              error={this.state.pwerror}
                              onChange={this.handleOnChange}
                            />
                          </Form.Field>

                          <Form.Field>
                            <Checkbox label="Remember me" />
                          </Form.Field>

                          <Form.Field>
                            <div
                              className="forgotpass"
                              onClick={this.forgotPassword}
                            >
                              Forgot your password?
                            </div>
                          </Form.Field>

                          <Form.Group>
                            <Button
                              color="purple"
                              type="submit"
                              loading={this.state.loading}
                              onClick={() => {
                                this.signIn();
                              }}
                            >
                              Login
                            </Button>
                            <Button
                              color="purple"
                              type="submit"
                              onClick={() => {
                                this.routeTo("register");
                              }}
                            >
                              Signup
                            </Button>
                          </Form.Group>
                          <Header as="h3" color="red">
                            {this.state.errorMessage}
                          </Header>
                        </Fragment>
                      ) : (
                        <Reset click={this.forgotPassword} />
                      )}
                    </Form>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Segment>
          </GridColumn>
          <GridColumn
            computer={5}
            tablet={4}
            mobile={2}
            widescreen={6}
          ></GridColumn>
        </GridRow>
      </Grid>
    );
  }
}

export default withRouter(Login);
