import React, { Component } from "react";
import {
  Container,
  Menu,
  Grid,
  GridRow,
  Dropdown,
  Icon
} from "semantic-ui-react";
import { connect } from "react-redux";
import { ClearUser } from "../Actions/UsersActions";
import { SignOut } from "../Util/Auth";
import { withRouter } from "react-router-dom";
import _ from "lodash";

class Navigation extends Component {
  state = {
    username: ""
  };

  routeTo = route => {
    this.props.history.push(`/${route}`);
  };

  render() {
    const { pathname } = this.props.location;
    return (
      <Grid padded>
        <GridRow stretched>
          <Menu fixed="top">
            <Container>
              <Menu.Item position="left" onClick={() => this.routeTo("home")}>
                <img
                  alt="Company logo small"
                  src="assets/logo.png"
                  srcSet="assets/logo@2x.png"
                />
              </Menu.Item>
              {!_.includes(
                ["/home", "/register", "/login", "/policy", "/terms"],
                pathname
              ) ? (
                <Menu.Menu position="right">
                  <Dropdown
                    trigger={
                      <span>
                        <Icon name="user circle" size="large" />{" "}
                        {_.get(this.props, "user.firstName", null)}
                      </span>
                    }
                    pointing
                    className="link item"
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => this.routeTo("account")}>
                        <Icon name="user" />
                        Account
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => {
                          SignOut((err, success) => {
                            if (!err) {
                              this.props.clearUser();
                              this.routeTo("home");
                            }
                          });
                        }}
                      >
                        <Icon name="sign-out" />
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Menu>
              ) : null}
            </Container>
          </Menu>
        </GridRow>
        <GridRow stretched>{this.props.children}</GridRow>
      </Grid>
    );
  }
}

let mapStateToProps = (state, props) => {
  return { user: state.user.user };
};

let mapDispatchToProps = dispatch => {
  return {
    clearUser: () => {
      dispatch(ClearUser());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Navigation));
