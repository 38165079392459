import {
  LIST_ASSESMENTS,
  CREATE_ASSESMENT,
  GET_ASSESMENT,
  SELECT_ASSIGNMENT,
  UPDATE_ASSESMENT,
  UPDATE_ASSESMENT_PAYMENT,
  GET_ASSESMENT_QUESTION,
  UPDATE_ASSESMENT_QUESTION,
  ERROR_ASSESMENT,
  GET_MOREINFO,
  CLEAR_MOREINFO,
  LOADING
} from "../Constants/Assesments";
import _ from "lodash";

const initialState = {
  assessmentId: null,
  questionId: null,
  assesments: null,
  assesment: null,
  paymentStatus: null,
  paystatus: null,
  assesQuestions: [],
  assesCategories: [],
  loading: false,
  categoryScores: [],
  totalScore: null,
  error: null,
  progress: 0,
  moreinfo: null
};

let checkQuestionAnswers = (state, newAssesQuestions) => {
  let count = 0;
  _.map(newAssesQuestions, category => {
    if (!_.isUndefined(_.get(category, "answer"))) {
      count++;
    }
  });
  return (count / _.get(state, "totalQuestions", 100)) * 100;
};

let updateQuestionAnswer = (state, payload) => {
  let category = _.get(state, "assesQuestions").find(
    category =>
      category.categoryId === payload.categoryId &&
      category.questionId === payload.questionId
  );

  let categoryIndex = _.get(state, "assesQuestions").findIndex(
    category =>
      category.categoryId === payload.categoryId &&
      category.questionId === payload.questionId
  );

  let newcategory = {
    ...category,
    answer: payload.answer,
    score: payload.score
  };

  let newAssesQuestions = [
    ...state.assesQuestions.slice(0, categoryIndex),
    newcategory,
    ...state.assesQuestions.slice(++categoryIndex)
  ];
  return {
    ...state,
    progress: checkQuestionAnswers(state, newAssesQuestions),
    totalScore: null,
    assesQuestions: newAssesQuestions
  };
};

let questionSort = payload => {
  return Array.prototype.concat.apply(
    [],
    payload.map(category => category.questions)
  );
};

let categorySort = payload => {
  return payload.map(category => {
    return {
      categoryName: category.categoryName,
      categoryId: category.categoryId,
      totalQuestions: category.totalQuestions,
      categorySortId: category.categorySortId
    };
  });
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LIST_ASSESMENTS:
      return {
        ...initialState,
        loading: false,
        assesments: _.get(payload, "length", 0) > 0 ? payload : []
      };
    case CREATE_ASSESMENT:
      return {
        ...state,
        assesment: payload,
        assessmentId: payload.id,
        paymentStatus: null,
        categoryScores: [],
        totalScore: 0,
        progress: 0
      };
    case SELECT_ASSIGNMENT:
      return { ...state, assessmentId: payload, progress: 0 };
    case GET_ASSESMENT:
      return {
        ...state,
        categoryScores: _.get(payload, "categoryScores", []),
        assesment: payload,
        assessmentId: payload.id,
        totalScore: _.get(payload, "totalScore", null),
        loading: false,
        paymentStatus: payload.paymentStatus
      };
    case UPDATE_ASSESMENT:
      return { ...state, ...payload, assesQuestions: [] };
    case GET_ASSESMENT_QUESTION:
      return {
        ...state,
        assesQuestionsReport: payload,
        assesQuestions: questionSort(payload),
        categorySort: categorySort(payload),
        paystatus: null,
        progress: checkQuestionAnswers(state, questionSort(payload))
      };
    case UPDATE_ASSESMENT_PAYMENT:
      return {
        ...state,
        assesment: payload,
        paymentStatus: payload.paymentStatus,
        paystatus: payload.paymentStatus,
        totalQuestions: _.get(payload, "totalQuestions", 0)
      };
    case UPDATE_ASSESMENT_QUESTION:
      return updateQuestionAnswer(state, payload);

    case GET_MOREINFO:
      return { ...state, moreinfo: payload };
    case CLEAR_MOREINFO:
      return { ...state, moreinfo: null };
    case LOADING:
      return { ...state, loading: payload };
    case ERROR_ASSESMENT:
      return { ...state, error: payload, loading: false };

    default:
      return state;
  }
};
