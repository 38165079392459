import React from "react";
import GuageChart from "./GuageChart";
import {
  GridRow,
  GridColumn,
  Grid,
  Card,
  Button,
  Header
} from "semantic-ui-react";
import moment from "moment";

export default function AssesmentCard({
  id,
  status,
  total,
  totalQuestions,
  totalPercentage,
  lastCategoryId,
  lastQuestionId,
  paymentStatus,
  getAssesment,
  getReport,
  updatedOn,
  createdOn
}) {
  return (
    <Card
      onClick={() => {
        if (status === "COMPLETED") {
          getReport(id);
        } else {
          getAssesment({
            assessmentId: id,
            lastCategoryId: lastCategoryId,
            lastQuestionId: lastQuestionId,
            paymentStatus: paymentStatus,
            status: status
          });
        }
      }}
    >
      {totalPercentage === 0 ? (
        <Grid padded>
          <GridRow>
            <GridColumn>
              <Header
                textAlign="center"
                color="grey"
                style={{ height: "150px", paddingTop: "25px" }}
              >
                Please take your AI Readiness Assessment again after you improve
                your information infrastructure
              </Header>
            </GridColumn>
          </GridRow>
        </Grid>
      ) : (
        <GuageChart total={totalPercentage} />
      )}

      <Card.Content>
        <Card.Meta>
          {moment(createdOn).format("MMMM Do YYYY, h:mm a")}
        </Card.Meta>
        <Card.Description>
          <Grid padded>
            <GridRow>
              {totalPercentage >= 90
                ? "You are AI ready and you can spend your money on AI projects"
                : "You have work to do and you should not spend your money on AI projects yet"}
            </GridRow>
            <GridRow>
              <GridColumn width={8}></GridColumn>
              <GridColumn width={8}>
                {status === "COMPLETED" ? (
                  <Button size="tiny" className="buttoncurved" color="green">
                    {status}
                  </Button>
                ) : (
                  <Button size="tiny" className="buttoncurved">
                    {status}
                  </Button>
                )}
              </GridColumn>
            </GridRow>
          </Grid>
        </Card.Description>
      </Card.Content>
    </Card>
  );
}
