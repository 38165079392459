import React, { Fragment } from "react";
import {
  Button,
  Progress,
  Grid,
  GridRow,
  GridColumn,
  Header,
  Loader,
  Dimmer
} from "semantic-ui-react";
import MoreInfo from "./MoreInfo";
import _ from "lodash";

export default function AssementAnswer({
  question,
  markAnswer,
  moreinfo,
  getMoreInfo,
  getCategoyProgress
}) {
  return (
    <Grid>
      {!_.isEmpty(question) ? (
        <Fragment>
          <GridRow style={{ height: "12vh" }}>
            <GridColumn width={16}>
              <Header as="h3">
                {question.questionNumber}.{" "}
                {_.get(question, "question.title", null)}
              </Header>
            </GridColumn>
            <GridColumn width={16}>
              <MoreInfo
                no={question.questionNumber}
                questionId={question.questionId}
                categoryId={question.categoryId}
                moreinfo={moreinfo}
                getMoreInfo={getMoreInfo}
                quiz={_.get(question, "question.title", null)}
              />
            </GridColumn>
          </GridRow>
          <GridRow style={{ height: "25vh" }}>
            <GridColumn width={2}></GridColumn>
            <GridColumn width={4}>
              <Button
                basic={_.get(question, "answer", null) === "YES" ? false : true}
                color={
                  _.get(question, "answer", null) === "YES" ? "green" : null
                }
                className="buttoncircular"
                onClick={e => {
                  markAnswer(
                    question.questionId,
                    question.categoryId,
                    question.assessmentId,
                    "YES"
                  );
                }}
              >
                Yes
              </Button>
            </GridColumn>
            <GridColumn width={4}>
              <Button
                basic={_.get(question, "answer", null) === "NO" ? false : true}
                color={_.get(question, "answer", null) === "NO" ? "red" : null}
                className="buttoncircular"
                onClick={e => {
                  markAnswer(
                    question.questionId,
                    question.categoryId,
                    question.assessmentId,
                    "NO"
                  );
                }}
              >
                No
              </Button>
            </GridColumn>
            <GridColumn width={4}>
              <Button
                basic={
                  _.get(question, "answer", null) === "IN_PROGRESS"
                    ? false
                    : true
                }
                color={
                  _.get(question, "answer", null) === "IN_PROGRESS"
                    ? "blue"
                    : null
                }
                className="buttoncircular"
                onClick={e => {
                  markAnswer(
                    question.questionId,
                    question.categoryId,
                    question.assessmentId,
                    "IN_PROGRESS"
                  );
                }}
              >
                WORKING ON IT
              </Button>
            </GridColumn>
            <GridColumn width={2}></GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={16}>
              <Progress
                percent={getCategoyProgress || 0}
                size="small"
                color="green"
              />
            </GridColumn>
          </GridRow>
        </Fragment>
      ) : (
        <Dimmer active inverted>
          <Loader size="huge">Loading</Loader>
        </Dimmer>
      )}
    </Grid>
  );
}
