import React, { Component, Fragment } from "react";
import {
  Container,
  GridColumn,
  GridRow,
  Label,
  Button,
  Loader,
  Dimmer,
  Grid,
  Placeholder,
  Message,
  Segment,
  Header,
  List
} from "semantic-ui-react";
import GuageChart from "../Components/GuageChart";
import _ from "lodash";

class AssesmentResult extends Component {
  renderItemList = () => {
    return _.get(this.props, "categoryScores").map(category => {
      return (
        <List.Item key={category.categoryId}>
          <Grid>
            <GridRow>
              <GridColumn computer={2} mobile={2} tablet={2}>
                <Label>
                  <Header as="h3">{category.score}</Header>
                </Label>
              </GridColumn>
              <GridColumn computer={14} mobile={14} tablet={14}>
                <Container fluid textAlign="left">
                  <Header as="h4" color="blue">
                    {category.categoryName}
                  </Header>
                  <Header as="h5" color="grey">
                    Yes{"    "}
                    <b>
                      <Label size="mini" color="green" horizontal>
                        {category.yesCount}
                      </Label>
                    </b>
                    {"  "}| No{"    "}
                    <b>
                      <Label size="mini" color="red" horizontal>
                        {category.noCount}
                      </Label>
                    </b>
                    {"  "}| In Progress{"    "}
                    <b>
                      <Label size="mini" color="orange" horizontal>
                        {category.inProgressCount}
                      </Label>
                    </b>
                  </Header>
                </Container>
              </GridColumn>
            </GridRow>
          </Grid>
        </List.Item>
      );
    });
  };

  render() {
    return (
      <GridRow>
        <GridColumn
          widescreen={5}
          computer={1}
          tablet={2}
          mobile={2}
        ></GridColumn>
        <GridColumn widescreen={4} computer={6} tablet={12} mobile={12}>
          <Grid padded>
            <GridRow>
              <GridColumn width={16}>
                {_.get(this.props, "totalScore") ? (
                  <GuageChart total={_.get(this.props, "totalScore")} />
                ) : (
                  <Placeholder className="haflcircle">
                    <Placeholder.Image />
                  </Placeholder>
                )}
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn computer={1} tablet={1} only="tablet computer" />
              <GridColumn computer={14} tablet={14} mobile={16}>
                {_.isEqual(
                  _.get(this.props, "location.state.from"),
                  "assesment"
                ) ? (
                  <Message
                    color="green"
                    icon="inbox"
                    size="small"
                    header="Please check your email!"
                    content="A detailed action plan report has been sent
        via email, Please check and make any improvements on the assessment if necessary"
                  />
                ) : null}
              </GridColumn>
              <GridColumn computer={1} tablet={1} only="tablet computer" />
            </GridRow>
          </Grid>
        </GridColumn>
        <GridColumn only="mobile tablet" tablet={2} mobile={2}></GridColumn>
        <GridColumn only="mobile tablet" tablet={2} mobile={1}></GridColumn>
        <GridColumn widescreen={10} computer={6} tablet={12} mobile={14}>
          <Segment.Group>
            <Segment>
              <Header>Assessment Categories</Header>
            </Segment>
            <Segment
              style={{ minHeight: "10vh" }}
              loading={_.get(this.props, "categoryScores.length", 0) <= 0}
            >
              <List relaxed selection>
                {this.renderItemList()}
              </List>
            </Segment>
          </Segment.Group>
        </GridColumn>
        <GridColumn
          widescreen={3}
          computer={1}
          tablet={1}
          mobile={1}
        ></GridColumn>
      </GridRow>
    );
  }
}

export default AssesmentResult;
