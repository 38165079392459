import React, { Component } from "react";
import {
  Grid,
  GridRow,
  GridColumn,
  Container,
  Header,
  List,
  Segment
} from "semantic-ui-react";

class TermsOfUse extends Component {
  render() {
    return (
      <Grid padded>
        <GridRow>
          <GridColumn width={1} />
          <GridColumn width={14}>
            <Container textAlign="justify" fluid>
              <Header as="h1">AUTHORIZED USER TERMS OF USE</Header>
              <p>
                These Authorized User Terms of Use ("Terms of Use") govern your
                use of the [IDENTIFY SERVICE] (the “Service”), including all
                user manuals, technical manuals, and any other materials
                provided by Provider, in printed, electronic, or other form,
                that describe the Service or its use or specifications (the
                “Documentation”) provided to you (“you” or “your”) for use
                pursuant to and subject to a Software as a Service Agreement
                (the “SaaS Agreement") between InCite Logix LLC (“Provider”) and
                your employer or other person or entity who has purchased the
                Service for your authorized use (“Customer”). BY CLICKING THE
                “ACCEPT” BUTTON/CHECKING THE “ACCEPT” BOX YOU: (i) REPRESENT
                THAT YOU ARE DULY AUTHORIZED BY CUSTOMER TO ACCESS AND USE THE
                SERVICE; AND (ii) ACCEPT THESE AUTHORIZED USER TERMS AND AGREE
                THAT YOU ARE LEGALLY BOUND BY THEM. IF YOU DO NOT AGREE TO THESE
                TERMS OF USE, DO NOT CLICK THE “ACCEPT” BUTTON/CHECK THE
                “ACCEPT” BOX AND YOU WILL HAVE NO RIGHTS OR PERMISSIONS TO, AND
                MUST NOT ACCESS OR USE, THE SERVICE.
              </p>

              <p>
                1. <strong>Provision of Access.</strong> Subject to your strict
                compliance with these Terms of Use, Provider hereby grants you a
                non-exclusive, non-transferable, limited right to access and use
                the Service solely in accordance with the Documentation and for
                Customer’s internal business purposes. The foregoing right will
                terminate immediately on the earlier to occur of:
                <List style={{ "margin-left": "25px" }}>
                  <List.Item>
                    <strong>a</strong>. &nbsp;the expiration or earlier
                    termination of the SaaS Agreement between Provider and
                    Customer; or
                  </List.Item>
                  <List.Item>
                    <strong>b</strong>. &nbsp;your ceasing to be authorized by
                    Customer to use the Service for any reason.
                  </List.Item>
                </List>
              </p>

              <p>
                2. <strong>Use Restrictions.</strong> You shall not, directly or
                indirectly:
                <List style={{ "margin-left": "25px" }}>
                  <List.Item>
                    <strong>a</strong>. &nbsp;use the Service or Documentation
                    beyond the scope of these Terms of Use;
                  </List.Item>
                  <List.Item>
                    <strong>b</strong>. &nbsp;provide any other Person who is
                    not an Authorized User (as defined in the SaaS Agreement)
                    with access to or use of the Service or Documentation;
                  </List.Item>
                  <List.Item>
                    <strong>c</strong>. &nbsp;copy, modify, translate, adapt, or
                    otherwise create derivative works or improvements of the
                    Service or Documentations;{" "}
                  </List.Item>
                  <List.Item>
                    <strong>d</strong>. &nbsp;rent, lease, lend, sell,
                    sublicense, assign, distribute, publish, transfer, or
                    otherwise make available the Service or Documentation to any
                    third party;{" "}
                  </List.Item>
                  <List.Item>
                    <strong>e</strong>. &nbsp;reverse engineer, disassemble,
                    decompile, decode, or otherwise attempt to derive or gain
                    access to the source code of the Service;{" "}
                  </List.Item>
                  <List.Item>
                    <strong>f</strong>. &nbsp;input, upload, transmit, or
                    otherwise provide to or through the Service, any information
                    or materials that are unlawful or injurious, or contain,
                    transmit, or activate any harmful code;
                  </List.Item>
                  <List.Item>
                    <strong>g</strong>. &nbsp;damage, destroy, disrupt, disable,
                    impair, interfere with, or otherwise impede or harm in any
                    manner the Service or Provider's provision of service to any
                    third party;
                  </List.Item>
                  <List.Item>
                    <strong>h</strong>. &nbsp;remove, delete, alter, or obscure
                    any trademarks, specifications, Documentation, warranties,
                    or disclaimers, or any copyright, trademark, patent, or
                    other intellectual property or proprietary rights notices
                    from any Service or Documentation, including any copy
                    thereof;
                  </List.Item>
                  <List.Item>
                    <strong>i</strong>. &nbsp;access or use the Service or
                    Documentation in any manner or for any purpose that
                    infringes, misappropriates, or otherwise violates any
                    intellectual property right or other right of any third
                    party, or that violates any law;
                  </List.Item>
                  <List.Item>
                    <strong>j</strong>. &nbsp;access or use the Service or
                    Documentation for purposes of competitive analysis of the
                    Service or Documentation, the development, provision, or use
                    of a competing software service or product or any other
                    purpose that is to Provider’s detriment or commercial
                    disadvantage; or
                  </List.Item>
                  <List.Item>
                    <strong>k</strong>. &nbsp;use the Service or Documentation
                    in, or in association with, the design, construction,
                    maintenance, or operation of any hazardous environments or
                    systems, including:
                    <List.List>
                      <List.Item>
                        {" "}
                        <strong>i</strong>. &nbsp;power generation systems;
                      </List.Item>
                      <List.Item>
                        <strong>ii</strong>. &nbsp;aircraft navigation or
                        communication systems, air traffic control systems, or
                        any other transport management systems;
                      </List.Item>
                      <List.Item>
                        <strong>iii</strong>. &nbsp;safety-critical
                        applications, including medical or life-support systems,
                        vehicle operation applications, or any police, fire, or
                        other safety response systems; and
                      </List.Item>
                      <List.Item>
                        <strong>iv</strong>. &nbsp;military or aerospace
                        applications, weapons systems, or environments.
                      </List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item></List.Item>
                  <List.Item></List.Item>
                </List>
              </p>

              <p>
                3. <strong>Compliance Measures</strong>
                <List style={{ "margin-left": "25px" }}>
                  <List.Item>
                    <strong>a</strong>. The Service may contain technological
                    copy protection or other security features designed to
                    prevent unauthorized use of the Service, including features
                    to protect against use of the Service:
                    <List.List>
                      <List.Item>
                        <strong>i</strong>. beyond the scope of the rights
                        granted to pursuant to Section 1; or
                      </List.Item>
                      <List.Item>
                        <strong>ii</strong>. prohibited under Section 2.
                      </List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>
                    <strong>b</strong>. You shall not, and shall not attempt to,
                    remove, disable, circumvent, or otherwise create or
                    implement any workaround to, any such copy protection or
                    security features.
                  </List.Item>
                </List>
              </p>

              <p>
                4. <strong>Collection and Use of Information</strong>Provider
                may, directly or indirectly through the services of others,
                collect and store information regarding use of the Service and
                about equipment on which the Service is accessed. You agree that
                Provider may use such information for any purpose and in any
                manner permitted by law.
              </p>

              <p>
                5. <strong>Intellectual Property Rights</strong>You do not
                acquire any ownership interest in the Service or Documentation
                under the SaaS Agreement or these Terms of Use, or any other
                rights to the Service and Documentation other than to use the
                Service and Documentation in accordance with the rights granted
                under these Terms of Use, subject to all terms, conditions, and
                restrictions. Provider and its licensors and service providers
                reserve and shall retain their entire right, title, and interest
                in and to the Service and Documentation and all intellectual
                property rights arising out of or relating to the Service and
                Documentation, subject to the rights expressly granted to
                Customer in the SaaS Agreement. You shall use commercially
                reasonable efforts to safeguard the Services and Documentation
                (including all copies thereof) from infringement,
                misappropriation, theft, misuse, or unauthorized access.
              </p>

              <p>
                6. <strong>Disclaimer of Liability</strong>IN NO EVENT WILL
                PROVIDER OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE
                LICENSORS OR SERVICE PROVIDERS, BE LIABLE TO YOU FOR ANY USE,
                INTERRUPTION, DELAY, OR INABILITY TO USE THE SOFTWARE. YOU ARE
                PROVIDED THE SERVICE AND DOCUMENTATION PURSUANT TO THE SAAS
                AGREEMENT BETWEEN PROVIDER AND CUSTOMER, SOLELY FOR THE BENEFIT
                OF CUSTOMER AND AT CUSTOMER’S DISCRETION. YOU ACKNOWLEDGE THAT
                YOU HAVE NO RIGHTS UNDER THAT AGREEMENT INCLUDING ANY RIGHTS TO
                ENFORCE ANY OF ITS TERMS. ANY OBLIGATION OR LIABILITY PROVIDER
                OR ITS AFFILIATES, OR ANY OF ITS OR THEIR LICENSORS OR SERVICE
                PROVIDERS MAY HAVE WITH RESPECT TO YOUR USE OR INABILITY TO USE
                THE SOFTWARE SHALL BE SOLELY TO CUSTOMER PURSUANT TO THAT
                AGREEMENT AND SUBJECT TO ALL LIMITATIONS OF LIABILITY SET FORTH
                THEREIN.
              </p>

              <p>
                7. <strong>Export Regulation</strong>. The Service and
                Documentation may be subject to US export control laws,
                including the US Export Administration Act and its associated
                regulations. You shall not, directly or indirectly, export,
                re-export, or release the Service or Documentation to, or make
                the Software or Documentation accessible from, any jurisdiction
                or country to which export, re-export, or release is prohibited
                by law, rule, or regulation. You shall comply with all
                applicable federal laws, regulations, and rules, and complete
                all required undertakings (including obtaining any necessary
                export license or other governmental approval), prior to
                exporting, re-exporting, releasing, or otherwise making the
                Service or Documentation available outside the US.
              </p>

              <p>
                8. <strong>Governing Law</strong>These Terms of Use are governed
                by and construed in accordance with the internal laws of the
                State of Texas without giving effect to any choice or conflict
                of law provision or rule.
              </p>
            </Container>
          </GridColumn>
          <GridColumn width={1} />
        </GridRow>
      </Grid>
    );
  }
}

export default TermsOfUse;
