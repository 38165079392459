import Auth from "@aws-amplify/auth";
import Analytics from "@aws-amplify/analytics";

export const getIdToken = async () => {
  const creds = await Auth.currentSession();
  return creds.idToken.jwtToken;
};

export const isAuthenticated = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (e) {
    return false;
  }
};

const clearState = () => {
  localStorage.removeItem("airr_meta");
};

export const SignUp = ({ username, password }, cb) => {
  Auth.signUp({
    username: username,
    password: password
  })
    .then(success => {
      let { userSub } = success;
      Analytics.updateEndpoint({
        userId: userSub,
        address: username
      });
      cb(null, success);
    })
    .catch(err => cb(err, null));
};

export const SignIn = ({ username, password }, cb) => {
  Auth.signIn(username, password)
    .then(success => {
      let { username: userSub } = success;
      Analytics.updateEndpoint({
        userId: userSub,
        address: username,
        channelType: "EMAIL"
      });
      cb(null, success);
    })
    .catch(err => cb(err, null));
};

export const SignOut = cb => {
  Auth.signOut()
    .then(data => {
      clearState();
      cb(null, data);
    })
    .catch(err => {
      cb(err, null);
    });
};

export const ForgotPassword = ({ username }, cb) => {
  Auth.forgotPassword(username)
    .then(data => {
      cb(null, data);
    })
    .catch(err => {
      cb(err, null);
    });
};

export const ForgotPasswordSubmit = ({ username, code, new_password }, cb) => {
  Auth.forgotPasswordSubmit(username, code, new_password)
    .then(data => {
      cb(null, data);
    })
    .catch(err => {
      cb(err, null);
    });
};
