import React, { Component } from "react";
import _ from "lodash";
import { Label, Menu } from "semantic-ui-react";

export default class MenuExampleVertical extends Component {
  renderCategories = (activeCategoryName, categories) => {
    if (!_.isEmpty(categories)) {
      return categories.map(category => {
        return (
          <Menu.Item
            key={category.categoryId}
            name={category.categoryName}
            active={activeCategoryName === category.categoryName}
            onClick={(e, c) => {
              this.props.selectCategory(category.categorySortId);
              this.props.handleCategoryClick(e, c);
            }}
          >
            <Label color="teal">{category.totalQuestions}</Label>
            {category.categoryName}
          </Menu.Item>
        );
      });
    } else {
      return <Menu.Item>Loading...</Menu.Item>;
    }
  };

  render() {
    const { categories, activeCategoryName } = this.props;
    return (
      <Menu vertical>
        {this.renderCategories(activeCategoryName, categories)}
      </Menu>
    );
  }
}
