import React, { Component, Fragment, createRef } from "react";
import {
  Button,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Input,
  Image,
  Checkbox,
  Container
} from "semantic-ui-react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { ForgotPassword, ForgotPasswordSubmit } from "../Util/Auth";
import { withRouter } from "react-router-dom";

class Reset extends Component {
  state = {
    reset: false,
    errorMessage: "",
    email: "",
    code: "",
    password01: "",
    password02: ""
  };

  routeTo = route => {
    this.props.history.push(`/${route}`);
  };

  forgotPassword = () => {
    let { email } = this.state;
    ForgotPassword({ username: email }, (error, success) => {
      if (error) {
        this.setState({ errorMessage: error.message });
      } else {
        this.setState({ uemail: email, email: "", reset: true });
      }
    });
  };

  forgotPasswordSubmit = () => {
    let { uemail, code, password01, password02 } = this.state;
    let { routeTo } = this;
    let { click } = this.props;
    ForgotPassword(
      { username: uemail, code, new_password: password01 },
      (error, success) => {
        if (!_.isNull(error)) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState(
            { code: "", password01: "", password02: "", uemail: "" },
            () => {
              click();
            }
          );
        }
      }
    );
  };

  handleOnChange = (e, { name, value }) =>
    this.setState({ [name]: value, errorMessage: "" });

  resetFields = cb =>
    this.setState({ code: "", password01: "", password02: "" }, () => {
      cb();
    });

  checkPasswords = () =>
    _.isEqual(
      _.get(this.state, "password01"),
      _.get(this.state, "password02")
    ) &&
    !_.isEqual(_.get(this.state, "password01"), "") &&
    !_.isEqual(_.get(this.state, "password02"), "");

  render() {
    if (this.state.reset) {
      return (
        <Fragment>
          <Form.Field>
            <label>Code</label>
            <Input
              placeholder="Code"
              name="code"
              value={this.state.code}
              onChange={this.handleOnChange}
            />
          </Form.Field>
          <Form.Field>
            <label>New Password</label>
            <Input
              placeholder="New Password"
              type="password"
              name="password01"
              value={this.state.password01}
              onChange={this.handleOnChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Confirm Password</label>
            <Input
              placeholder="Confirm Password"
              type="password"
              name="password02"
              value={this.state.password02}
              onChange={this.handleOnChange}
            />
          </Form.Field>
          <Form.Group>
            <Form.Field>
              <Checkbox label="Remember me" />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <Header as="h3" color="red">
              {this.state.errorMessage}
            </Header>
          </Form.Field>
          <Form.Group>
            <Button
              type="submit"
              onClick={() => {
                if (this.checkPasswords()) {
                  this.forgotPasswordSubmit();
                } else {
                  this.setState({
                    errorMessage:
                      "The Password and Confirmation Password are not equal"
                  });
                }
              }}
              color="purple"
            >
              Submit
            </Button>
            <Button color="purple" onClick={this.props.click}>
              Cancel
            </Button>
          </Form.Group>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Form.Field>
            <label>
              Enter your email and we send you a password reset link.
            </label>
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <Input
              placeholder="Email"
              name="email"
              value={this.state.email}
              onChange={this.handleOnChange}
            />
          </Form.Field>
          <Form.Field>
            <Header as="h3" color="red">
              {this.state.errorMessage}
            </Header>
          </Form.Field>
          <Form.Group>
            <Button
              type="submit"
              onClick={() => {
                this.forgotPassword();
              }}
              color="purple"
            >
              Send Request
            </Button>
            <Button color="purple" onClick={this.props.click}>
              Cancel
            </Button>
          </Form.Group>
        </Fragment>
      );
    }
  }
}

export default withRouter(Reset);
