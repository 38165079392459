import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Dropdown
} from "semantic-ui-react";
import _ from "lodash";
import { SignOut } from "../Util/Auth";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

const HomepageHeading = ({ mobile, history, user }) => (
  <Container text>
    <img
      alt="Company logo"
      src="/assets/logo-vertical.png"
      srcSet="/assets/logo-vertical@2x.png"
    />
    <Header
      as="h1"
      content="Artificial Intelligence Readiness Made Simple"
      inverted
      style={{
        fontSize: mobile ? "1.5em" : "3em",
        fontWeight: "normal",
        marginBottom: 0,
        marginTop: mobile ? "0.25em" : "0.5em"
      }}
    />
    <Header
      as="h2"
      content="AIRR solutions allow you to manage Artificial Intelligence projects like Machine Learning at the right cost and with ease"
      inverted
      style={{
        fontSize: mobile ? "1em" : "1.5em",
        fontWeight: "normal",
        marginTop: mobile ? "0.5em" : "1.5em"
      }}
    />
    <Button
      color="purple"
      size={mobile ? "small" : "huge"}
      onClick={() => {
        if (_.isNil(user)) {
          history.push("/register");
        } else {
          history.push("/main");
        }
      }}
    >
      Get Started Now
      <Icon name="right arrow" />
    </Button>
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool
};

class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children, history, user } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            id="GetStarted"
            textAlign="center"
            style={{
              minHeight: 700,
              padding: "1em 0em",
              backgroundImage: `url(/assets/home_background.svg)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover"
            }}
            vertical
          >
            <Menu
              fixed={fixed ? "top" : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              style={{
                borderWidth: "0px",
                borderColor: "transparent"
              }}
              size="large"
            >
              <Container>
                <Menu.Item as="a" active href="#GetStarted">
                  GET STARTED NOW
                </Menu.Item>
                <Menu.Item as="a" href="#Benifits">
                  BENEFITS
                </Menu.Item>
                <Menu.Item as="a" href="#Services">
                  SERVICES
                </Menu.Item>
                <Menu.Item as="a" href="#AboutUs">
                  ABOUT US
                </Menu.Item>
                <Menu.Item position="right">
                  {!_.isNil(user) ? (
                    <Dropdown
                      style={{
                        paddingTop: "0px",
                        paddingBottom: "1px"
                      }}
                      trigger={
                        <span>
                          <Icon name="user circle" size="large" />{" "}
                          {_.get(user, "firstName", null)}
                        </span>
                      }
                      pointing
                      className="link item"
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            history.push("/main");
                          }}
                        >
                          <Icon name="columns" />
                          Dashboard
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={() => {
                            SignOut(() => {
                              window.location.reload();
                            });
                          }}
                        >
                          <Icon name="sign-out" />
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Fragment>
                      <Button
                        as="a"
                        inverted={!fixed}
                        onClick={() => {
                          history.push("/login");
                        }}
                      >
                        Log in
                      </Button>
                      <Button
                        as="a"
                        color="purple"
                        inverted={!fixed}
                        style={{ marginLeft: "0.5em" }}
                        onClick={() => {
                          history.push("/register");
                        }}
                      >
                        Sign Up
                      </Button>
                    </Fragment>
                  )}
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading user={user} history={history} />
          </Segment>
        </Visibility>
        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children, history, user } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation="push"
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item
            as="a"
            active
            href="#GetStarted"
            onClick={() => this.handleSidebarHide()}
          >
            GET STARTED NOW
          </Menu.Item>
          <Menu.Item
            as="a"
            href="#Benifits"
            onClick={() => this.handleSidebarHide()}
          >
            BENIFITS
          </Menu.Item>
          <Menu.Item
            as="a"
            href="#Services"
            onClick={() => this.handleSidebarHide()}
          >
            SERVICES
          </Menu.Item>
          <Menu.Item
            as="a"
            href="#AboutUs"
            onClick={() => this.handleSidebarHide()}
          >
            ABOUT US
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign="center"
            style={{
              minHeight: 350,
              padding: "1em 0em",
              backgroundImage: `url(/assets/home_background.svg)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover"
            }}
            vertical
          >
            <Container>
              <Menu
                inverted
                pointing
                secondary
                size="large"
                style={{
                  borderWidth: "0px",
                  borderColor: "transparent"
                }}
              >
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Item position="right">
                  {!_.isNil(user) ? (
                    <Dropdown
                      style={{
                        paddingTop: "0px",
                        paddingBottom: "1px"
                      }}
                      trigger={
                        <span>
                          <Icon name="user circle" size="large" />{" "}
                          {_.get(user, "firstName", null)}
                        </span>
                      }
                      pointing
                      className="link item"
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            history.push("/main");
                          }}
                        >
                          <Icon name="columns" />
                          Dashboard
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={() => {
                            SignOut(() => {
                              window.location.reload();
                            });
                          }}
                        >
                          <Icon name="sign-out" />
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Fragment>
                      <Button
                        as="a"
                        onClick={() => {
                          history.push("/login");
                        }}
                      >
                        Log in
                      </Button>
                      <Button
                        as="a"
                        color="purple"
                        style={{ marginLeft: "0.5em" }}
                        onClick={() => {
                          history.push("/register");
                        }}
                      >
                        Sign Up
                      </Button>
                    </Fragment>
                  )}
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile user={user} history={history} />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node
};

const ResponsiveContainer = ({ children, history, user }) => (
  <div>
    <DesktopContainer history={history} user={user}>
      {children}
    </DesktopContainer>
    <MobileContainer history={history} user={user}>
      {children}
    </MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node
};

const HomepageLayout = props => (
  <ResponsiveContainer {...props}>
    <Segment style={{ padding: "8em 0em" }} vertical id="Benifits">
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Benefits
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              Have access to penetrating list of management questions to
              determine if your firm is ready to meet your AI project needs.
            </p>
          </Grid.Column>
          <Grid.Column floated="right" width={6}>
            <Image bordered rounded size="large" src="/assets/BenifitsAI.jpg" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment style={{ padding: "8em 0em" }} vertical id="Services">
      <Container text>
        <Header as="h3" style={{ fontSize: "2em" }}>
          Services
        </Header>
        <p style={{ fontSize: "1.33em" }}>
          Covering 7 business functions and applicable in over 100 countries,
          AIRR manages your AI development projects and dramatically improves
          your agility to bring new AI projects to fruition at the lowest cost
          and in the fastest time
        </p>
      </Container>
    </Segment>
    <Segment style={{ padding: "8em 0em" }} vertical id="AboutUs">
      <Container text>
        <Header as="h3" style={{ fontSize: "2em" }}>
          About Us
        </Header>
        <p style={{ fontSize: "1.33em" }}>
          InCite Logix supplies both software and services necessary to help our
          Clients solve complex business problems. We focus on using Data as a
          competitive advantage. We utilize unique tools and methodologies to
          deliver faster, more accurate decision support. We work with Clients
          to deliver successful projects using pattern-recognition and
          forecasting (AI) and algorithms that capture workflow statistics
          (machine learning), providing foundations for step-change improvements
          and enhanced competitive advantage. We are a privately held start-up,
          located in Houston, Texas. We have unique experience in Refining,
          Chemical, Midstream and Petrochemical production, maintenance,
          reliability and Safety
        </p>
      </Container>
    </Segment>
    <Segment inverted vertical style={{ padding: "5em 0em" }}>
      <Container>
        <Grid inverted stackable>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header inverted as="h4" content="Company" />
              <List link inverted>
                <List.Item>
                  <Link to="/terms">Terms of Use</Link>
                </List.Item>
                <List.Item>
                  <Link to="/policy">Privacy Policy</Link>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4}>
              <Header inverted as="h4" content="Contact Us" />
              <p>
                InCite Logix
                <br /> 2101 Citywest Blvd.
                <br /> Suite 204 Houston, TX 77042
                <br />
                <br />
                833-722-2555
                <br />
                <br />
                <a
                  to="route"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/InCiteLogix/"
                >
                  <Icon inverted name="facebook official" size="big"></Icon>
                </a>
                <a
                  to="route"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/company/incite-logix/"
                >
                  <Icon inverted name="linkedin square" size="big"></Icon>
                </a>
                <a
                  to="route"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://twitter.com/@LogixIn"
                >
                  <Icon inverted name="twitter" size="big"></Icon>
                </a>
              </p>
            </Grid.Column>
            <Grid.Column width={6}></Grid.Column>
            <Grid.Column width={2}>
              <img
                alt="Company logo white"
                src="/assets/logo-vertical-white.png"
                srcSet="/assets/logo-vertical-white@2x.png"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
);

let mapStateToProps = (state, props) => {
  return {
    user: state.user.user
  };
};

let mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomepageLayout));
