import React, { Component, createRef } from "react";
import { Form, Header } from "semantic-ui-react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  injectStripe
} from "react-stripe-elements";

class AssesmentPay extends Component {
  state = {
    errorMessage: null
  };

  constructor(props) {
    super(props);
    this.formButton = createRef();
  }

  handleChange = ({ error }) => {
    this.setState({ errorMessage: error ? error.message : null });
  };

  handleSubmit = async evt => {
    evt.preventDefault();
    if (this.props.stripe) {
      let { token } = await this.props.stripe.createToken();
      this.props.makepaymnet(token);
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  componentDidMount() {
    this.props.takeRef(this.formButton);
  }

  render() {
    return (
      <Form
        onSubmit={evt => this.handleSubmit(evt)}
        ref={button => {
          this.formButton = button;
        }}
      >
        <Form.Field>
          <label>Name</label>
          <input placeholder="Name" />
        </Form.Field>
        <Form.Field>
          <label>Address</label>
          <input placeholder="Address" />
        </Form.Field>
        <Form.Field>
          <label>Card No</label>
          <div
            className="custominput"
            style={{
              width: "200px"
            }}
          >
            <CardNumberElement onChange={this.handleChange} />
          </div>
        </Form.Field>
        <Form.Group>
          <Form.Field>
            <label>Expiration</label>
            <div
              className="custominput"
              style={{
                width: "90px"
              }}
            >
              <CardExpiryElement onChange={this.handleChange} />
            </div>
          </Form.Field>
          <Form.Field>
            <label>CVC</label>
            <div
              className="custominput"
              style={{
                width: "90px"
              }}
            >
              <CardCvcElement onChange={this.handleChange} />
            </div>
          </Form.Field>
        </Form.Group>
        <Header as="h4" color="red">
          {this.state.errorMessage}
        </Header>
      </Form>
    );
  }
}

export default injectStripe(AssesmentPay);
