import React, { Component, Fragment } from "react";
import Linkify from "react-linkify";
import _ from "lodash";
import {
  Icon,
  Header,
  Modal,
  Button,
  Segment,
  Placeholder
} from "semantic-ui-react";

class MoreInfo extends Component {
  state = {
    modelOpen: false
  };

  OpenModel = () =>
    this.setState(state => {
      return { modelOpen: !state.modelOpen };
    });

  loadMoreInfo = (categoryId, questionId) => {
    this.props.getMoreInfo(categoryId, questionId);
  };

  render() {
    return (
      <Fragment>
        <Header
          color="blue"
          onClick={() => {
            this.loadMoreInfo(this.props.categoryId, this.props.questionId);
            this.OpenModel();
          }}
          size="small"
          className="select"
        >
          Need more information?
        </Header>
        <Modal open={this.state.modelOpen} onClose={this.OpenModel}>
          <Modal.Header>
            {this.props.no}. {this.props.quiz}
          </Modal.Header>
          <Modal.Content>
            <Segment vertical>
              {_.get(this.props, "moreinfo.why", null) ? (
                <Header as="h3">
                  Why is this needed for AI?
                  <Header.Subheader className="textformat">
                    <Linkify>{_.get(this.props, "moreinfo.why", null)}</Linkify>
                  </Header.Subheader>
                </Header>
              ) : (
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              )}
            </Segment>
            <Segment vertical>
              {_.get(this.props, "moreinfo.why", null) ? (
                <Header as="h3">
                  How do you acquire this capability?
                  <Header.Subheader className="textformat">
                    <Linkify>{_.get(this.props, "moreinfo.how", null)}</Linkify>
                  </Header.Subheader>
                </Header>
              ) : (
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              )}
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button primary icon onClick={this.OpenModel}>
              Got it <Icon name="right chevron" />
            </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

export default MoreInfo;
