import React from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  GridRow,
  GridColumn,
  Header,
  Container
} from "semantic-ui-react";

const NotFound = () => {
  return (
    <Grid
      padded
      style={{
        height: "100vh",
        backgroundImage: `url(/assets/home_background.svg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}
    >
      <GridRow>
        <GridColumn mobile={1} tablet={3} computer={4}></GridColumn>
        <GridColumn mobile={14} tablet={10} computer={8}>
          <Container text>
            <Header
              as="h1"
              style={{
                fontSize: "4em",
                fontWeight: "normal",
                marginBottom: 0,
                marginTop: "2.5em"
              }}
            >
              That Page doesn't exist!
            </Header>
            <Header
              as="h4"
              color="grey"
              inverted
              style={{
                fontSize: "1.5em",
                fontWeight: "normal",
                marginTop: "0.8em"
              }}
            >
              Sorry, the page you were looking for could not be found.
            </Header>
            <Header
              as="h6"
              color="grey"
              inverted
              style={{
                fontSize: "1em",
                fontWeight: "normal",
                marginTop: "0.5em"
              }}
            >
              Return to our <Link to="/">home page</Link>, or contact us if you
              can't find what you are looking for
            </Header>
          </Container>
        </GridColumn>
        <GridColumn mobile={1} tablet={3} computer={4}></GridColumn>
      </GridRow>
    </Grid>
  );
};

export default NotFound;
