import React from "react";
import Navigation from "./Containers/Navigation";
import Home from "./Containers/Home";
import Registration from "./Containers/Registration";
import Login from "./Containers/Login";
import Main from "./Containers/Main";
import Assesment from "./Containers/Assesment";
import Reset from "./Containers/Reset";
import Report from "./Containers/Report";
import Account from "./Containers/Account";
import PrivacyPolicy from "./Containers/PrivacyPolicy";
import TermsOfUse from "./Containers/TermsOfUse";
import NotFound from "./Components/NotFound";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./Store/Store";
import { saveState } from "./Util/Persist";
import { isAuthenticated } from "./Util/Auth";
import { ToastProvider } from "react-toast-notifications";

const store = configureStore();

store.subscribe(() => {
  saveState({
    category: store.getState().category,
    assesment: store.getState().assesment,
    user: store.getState().user
  });
});

function App() {
  return (
    <Provider store={store}>
      <ToastProvider>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={props => <Redirect to="/home" {...props} />}
            />
            <Route exact path="/home" render={props => <Home {...props} />} />
            <Route
              exact
              path="/register"
              render={props => <Registration {...props} />}
            />
            />
            <Route
              exact
              path="/main"
              render={props => <PrivateRoute component={Main} {...props} />}
            />
            <Route
              exact
              path="/assesment"
              render={props => (
                <PrivateRoute component={Assesment} {...props} />
              )}
            />
            <Route
              exact
              path="/report/:assessmentId"
              render={props => <PrivateRoute component={Report} {...props} />}
            />
            <Route
              exact
              path="/account"
              render={props => <PrivateRoute component={Account} {...props} />}
            />
            <Route
              exact
              path="/terms"
              render={props => (
                <PublicRoute component={TermsOfUse} {...props} />
              )}
            />
            <Route
              exact
              path="/policy"
              render={props => (
                <PublicRoute component={PrivacyPolicy} {...props} />
              )}
            />
            <Route exact path="/reset" render={props => <Reset {...props} />} />
            <Route exact path="/login" render={props => <Login {...props} />} />
            <Route path="*" render={props => <NotFound {...props} />} />
          </Switch>
        </Router>
      </ToastProvider>
    </Provider>
  );
}

const PublicRoute = ({ component: Component, ...rest }) => (
  <Navigation>
    <Component {...rest} public={true} />
  </Navigation>
);

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={async props =>
//       (await isAuthenticated()) ? (
//         <Navigation>
//           <Component {...props} />
//         </Navigation>
//       ) : (
//         <Redirect to="/login" />
//       )
//     }
//   />
// );

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Navigation>
        <Component {...props} />
      </Navigation>
    )}
  />
);

export default App;
