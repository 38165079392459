import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Grid,
  GridRow,
  GridColumn,
  Breadcrumb,
  Segment,
  Message,
  Header,
  Form,
  Input,
  Icon,
  Button,
  Dropdown
} from "semantic-ui-react";
import _ from "lodash";
import { GetUser, UpdateUser } from "../Actions/UsersActions";

const options = [
  { key: 1, text: "Petrochemical/Refining", value: "Petrochemical/Refining" },
  { key: 2, text: "Chemical Processing", value: "Chemical Processing" },
  {
    key: 3,
    text: "Storage/Pipeline/Transportation",
    value: "Storage/Pipeline/Transportation"
  },
  {
    key: 4,
    text: "Oil & Gas Exploration/Production",
    value: "Oil & Gas Exploration/Production"
  },
  {
    key: 5,
    text: "Power Generation/Utilities",
    value: "Power Generation/Utilities"
  },
  { key: 6, text: "Discreet Manufacturing", value: "Discreet Manufacturing" },
  { key: 7, text: "Food/Pharmaceuticaln", value: "Food/Pharmaceuticaln" },
  {
    key: 8,
    text: "Industrial Services/Products",
    value: "Industrial Services/Products"
  },
  {
    key: 8,
    text: "Other (Specify industry in the field below)",
    value: "Other (Specify industry in the field below)"
  }
];

class Account extends Component {
  state = {
    editing: false,
    firstName: null,
    lastName: null,
    departmentName: null,
    companyName: null,
    location: null,
    industryCategory: null,
    jobTitle: null
  };

  routeTo = route => {
    this.props.history.push(`/${route}`);
  };

  componentDidMount() {
    this.props.getUser();
  }

  handleOnChange = (e, { name, value }) =>
    this.setState({ [name]: value, errorMessage: null });

  editing = () => {
    this.setState((prevState, preProps) => {
      return { editing: !prevState.editing };
    });
  };

  updateUserData = () => {
    let {
      user: {
        firstName,
        lastName,
        departmentName,
        companyName,
        location,
        industryCategory,
        jobTitle
      }
    } = this.props;

    this.props.updateUser({
      firstName: _.get(this.state, "firstName") || firstName,
      lastName: _.get(this.state, "lastName") || lastName,
      departmentName: _.get(this.state, "departmentName") || departmentName,
      companyName: _.get(this.state, "companyName") || companyName,
      location: _.get(this.state, "location") || location,
      industryCategory:
        _.get(this.state, "industryCategory") || industryCategory,
      jobTitle: _.get(this.state, "jobTitle") || jobTitle
    });
  };

  render() {
    let { editing } = this.state;
    return (
      <Grid style={{ width: "100vw" }} padded>
        <GridRow>
          <GridColumn width={16}>
            <Breadcrumb>
              <Breadcrumb.Section
                link
                onClick={() => {
                  this.routeTo("main");
                }}
              >
                Home
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right chevron" />
              <Breadcrumb.Section link>Account</Breadcrumb.Section>
            </Breadcrumb>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn width={3}></GridColumn>
          <GridColumn width={10}>
            <Segment.Group>
              <Segment>
                <Header as="h2" color="grey">
                  <Icon name="user outline" />
                  <Header.Content>User Details</Header.Content>
                </Header>
              </Segment>
              <Segment>
                <Form>
                  <Form.Group>
                    <Form.Field>
                      <label>Firstname</label>
                      {editing ? (
                        <Input
                          placeholder={
                            _.get(this.props.user, "firstName", null) ||
                            "Firstname"
                          }
                          name="firstName"
                          disabled={!editing}
                          onChange={this.handleOnChange}
                        />
                      ) : (
                        <Message>
                          {_.get(this.props.user, "firstName", null)}
                        </Message>
                      )}
                    </Form.Field>
                    <Form.Field>
                      <label>Lastname</label>
                      {editing ? (
                        <Input
                          placeholder={
                            _.get(this.props.user, "lastName", null) ||
                            "Lastname"
                          }
                          name="lastName"
                          onChange={this.handleOnChange}
                        />
                      ) : (
                        <Message>
                          {_.get(this.props.user, "lastName", null)}
                        </Message>
                      )}
                    </Form.Field>
                  </Form.Group>
                  <Form.Field>
                    <label>Department</label>
                    {editing ? (
                      <Input
                        placeholder={
                          _.get(this.props.user, "departmentName", null) ||
                          "Department"
                        }
                        name="departmentName"
                        onChange={this.handleOnChange}
                      />
                    ) : (
                      <Message>
                        {_.get(this.props.user, "departmentName", null)}
                      </Message>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Company</label>
                    {editing ? (
                      <Input
                        placeholder={
                          _.get(this.props.user, "companyName", null) ||
                          "Company"
                        }
                        name="companyName"
                        onChange={this.handleOnChange}
                      />
                    ) : (
                      <Message>
                        {_.get(this.props.user, "companyName", null)}
                      </Message>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Location</label>
                    {editing ? (
                      <Input
                        placeholder={
                          _.get(this.props.user, "location", null) || "Location"
                        }
                        name="location"
                        onChange={this.handleOnChange}
                      />
                    ) : (
                      <Message>
                        {_.get(this.props.user, "location", null)}
                      </Message>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Industry Category</label>
                    {editing ? (
                      <Dropdown
                        search
                        selection
                        wrapSelection={false}
                        options={options}
                        placeholder="Choose an option"
                      />
                    ) : (
                      <Message>
                        {_.get(this.props.user, "industryCategory", null)}
                      </Message>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Job title</label>
                    {editing ? (
                      <Input
                        placeholder={
                          _.get(this.props.user, "jobTitle", null) ||
                          "Job title"
                        }
                        name="jobTitle"
                        onChange={this.handleOnChange}
                      />
                    ) : (
                      <Message>
                        {_.get(this.props.user, "jobTitle", null)}
                      </Message>
                    )}
                  </Form.Field>
                  <Button
                    onClick={() => {
                      if (editing) {
                        this.updateUserData();
                      } else {
                        this.setState({ editing: true });
                      }
                    }}
                  >
                    {editing ? "Save" : "Edit"}
                  </Button>
                  <Button onClick={() => this.setState({ editing: false })}>
                    Cancel
                  </Button>
                </Form>
              </Segment>
            </Segment.Group>
          </GridColumn>
          <GridColumn width={3}></GridColumn>
        </GridRow>
      </Grid>
    );
  }
}

let mapStateToProps = (state, props) => {
  return {
    user: state.user.user
  };
};

let mapDispatchToProps = dispatch => {
  return {
    getUser: () => {
      dispatch(GetUser());
    },
    updateUser: data => {
      dispatch(UpdateUser(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Account));
